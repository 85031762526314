
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        currencies: {
            currencies: [],
        }
    },
    mutations: {
        GET_CURRENCIES(state, payload) {
            state.currencies = payload
        },
    },
    actions: {
        getCurrencies({ commit }) {
            return new Promise((resolve, reject) => {
                return axios.get('/rateCurrencies/show')
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            })
        },
        syncCurrencies({ commit }) {
            return new Promise((resolve, reject) => {
                return axios.get('/rateCurrencies/syncCurrencies')
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            })
        },
       history({ commit }, params) {
            return new Promise((resolve, reject) => {
                return axios.get(`/rateCurrencies/history?symbol=${params.data.symbol}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
    getters: {}
}
