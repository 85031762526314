<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo"> </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="authLogin">
              <!-- email -->
              <b-form-group label="Usuario" label-for="username" class="mb-1"> 
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="username"
                    placeholder="User"
                    
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Contraseña</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>¿Olvido su contraseña?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-overlay
                :show="loadingData"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                  class="mt-1"
                >
                  {{ $t("auth.enter") }}
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
// import useJwt from "@/auth/jwt/useJwt";
// import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import userStoreModule from "../../apps/user/userStoreModule";
import { onUnmounted } from '@vue/composition-api'
import { setAuthLocalStorage, requestRefreshToken, logoutSessionIdle } from '@core/utils/auth'
export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    ToastificationContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loadingData: false,
      password: "",
      username: "",
      sideImg: require("@/assets/images/pages/logo.png"),

      // validation rules
      required
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/logo.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async authLogin() {
      try {
        const validation = await this.$refs.loginForm.validate()
        if (!validation) {
          return;
        }

        this.loadingData = true;
        const loginData = {
          username: this.username,
          password: this.password,
        };
        const res = await store.dispatch("app-user/authLogin", loginData);
        const userData = res.data;
        const objectSetLogin ={
          userData: userData,
          accessToken: userData.token,
          timeRefreshToken: userData.expiresIn,
          minRefreshToken: userData.expiresIn,
        }
        setAuthLocalStorage(objectSetLogin);
        logoutSessionIdle(document);
        this.$router.push({ name: "dashboard-ecommerce" });

      } catch (error) {
        console.log(error);

        // validar si el http code es 401
        if(error.response.status === 401){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notificacion",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notificacion",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: this.$t('error_message'),
            },
          });
        }


      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
