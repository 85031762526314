<template>
  <div>
    <b-modal
      scrollable
      ref="modalShowProducts"
      hide-footer
      :title="$t('client_products')"
    >
      <b-list-group v-if="listProducts.length > 0">
        <b-list-group-item class="font-weight-bold text-center">
          <b-row>
            <b-col cols="4">
              <span>{{ $t("product") }}</span>
            </b-col>
            <b-col cols="4">
              <span>{{ $t("credit_limit") }}</span>
            </b-col>
            <b-col cols="4" class="text-end">
              {{ $t("status") }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          v-for="(tr, indexTr) in listProducts"
          :key="indexTr"
          :class="[
            'font-weight-bold text-nowrap ',
            tr.status ? 'text-primary' : '',
          ]"
          :variant="tr.status ? '' : 'light'"
        >
          <b-row>
            <b-col cols="4" class="text-start">
              <span class="mr-1">
            <feather-icon icon="BoxIcon" size="16" />
          </span>
              <span>{{ tr.name }}</span>
            </b-col>
            <b-col cols="4" class="text-center">
              <span>
                <b-badge
                  v-if="tr.creditLimit > 0"
                  pill
                  :variant="`light-${
                    tr.creditLimit > tr.creditLimitUsed ? 'success' : 'danger'
                  }`"
                  class="text-capitalize"
                >
                  <span>
                    ${{ tr.creditLimitUsed }}
                    /
                  </span>
                  <span> ${{ tr.creditLimit }} </span>
                </b-badge>
              </span>
            </b-col>
            <b-col cols="4" class="text-center">
              <b-badge
                pill
                :variant="`light-${tr.status ? 'success' : 'danger'} mr-1`"
                class="text-capitalize"
              >
                {{ tr.status ? "Active" : "Inactive" }}
              </b-badge>
            </b-col>

          </b-row>
        </b-list-group-item>
      </b-list-group>

      <b-list-group v-else>
        <b-list-group-item>
          <span class="mr-1">
            <feather-icon icon="BoxIcon" size="16" />
          </span>
          <span>{{ $t("no_products") }}</span>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BFormInput,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    VBTooltip,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      customer: null,
    };
  },
  computed: {
    listProducts() {
      if (this.customer?.products?.length > 0) {
        const products = this.customer.products;
        return products.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    showModal() {
      this.$refs["modalShowProducts"].show();
    },
    hideModal() {
      this.$refs["modalShowProducts"].hide();
    },
    show(customer) {
      this.customer = customer;

      this.customer.products = customer.products?.map((tr) => {
        const creditProduct = customer.productCreditLimits?.find(
          (tr2) => tr2.productId === tr._id
        );
        // tr.creditLimit = creditProduct.creditLimit 
        // tr.creditLimitUsed = creditProduct.creditLimitUsed;

        tr.creditLimit = creditProduct?.creditLimit || 0;
        tr.creditLimitUsed = creditProduct?.creditLimitUsed || 0;
        return tr;
      });

      this.showModal();
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    onCopy(text, message) {
      console.log(text, message);

      navigator.clipboard.writeText(text);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckMarkIcon",
        },
      });
    },
  },
};
</script>
