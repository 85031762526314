<template>
   <div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  VBModal,
  VBToggle,
  BCarousel,
  BCarouselSlide,
  BModal
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    VBModal,
    vSelect,
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BModal
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
  },
  // add filters
    data() {
      return {
        name: '',
        nameState: null,
        submittedNames: []
      }
    },
    methods: {
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.name = ''
        this.nameState = null
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        // Push the name to submitted names
        this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      }
    }
  };
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
