var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.openModal)?_c('div',{staticClass:"mt-2"},[_c('b-modal',{attrs:{"title":"Open Game","hide-footer":"","modal-class":"modal-primary","centered":"","no-close-on-backdrop":""},on:{"hidden":_vm.resetModalData},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"variant":"transparent","no-wrap":""}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1 text-lg"},[_vm._v("Clientes")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customerOptions,"label":"name","placeholder":"Cliente","value":"","loading":_vm.isloadingDataCustomer},on:{"input":function($event){_vm.dataPlayer((_vm.isDemo = true))}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,false,885863460),model:{value:(_vm.selectCustomer),callback:function ($$v) {_vm.selectCustomer=$$v},expression:"selectCustomer"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1 text-lg"},[_vm._v("Usuario Demo")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.playersOptions,"label":"name","value":"","placeholder":"Selecione un usuario","disabled":!_vm.selectCustomer,"loading":_vm.isloadingDataPlayer},on:{"search":function (search) {
                this$1.search = search;
                this$1.dataPlayer();
              }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
              var name = ref.name;
              var currency = ref.currency;
return [_c('div',[_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(name))])],1),_c('div',[_c('feather-icon',{staticClass:"align-middle ml-1",attrs:{"icon":"ApertureIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(currency))])],1)]}},{key:"option",fn:function(ref){
              var name = ref.name;
              var currency = ref.currency;
return [_c('div',[_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":"UserIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(name))])],1),_c('div',[_c('feather-icon',{staticClass:"align-middle mr-50",attrs:{"icon":"ApertureIcon","size":"16"}}),_c('span',[_vm._v(" "+_vm._s(currency))])],1)]}}],null,false,753772144),model:{value:(_vm.selectPlayers),callback:function ($$v) {_vm.selectPlayers=$$v},expression:"selectPlayers"}}),(_vm.isUserNotFound)?_c('span',{staticClass:"text-xs text-danger"},[_vm._v("No se encontro ningun usuario demo, Busca un usuario para selecionar")]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":!_vm.selectCustomer && !_vm.selectPlayers,"variant":"primary"},on:{"click":_vm.launch}},[_vm._v("Launch")])],1),(_vm.resLaunch.data)?_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"mr-1 text-lg"},[_vm._v("Response Launch")]),_c('prism',{staticClass:"rounded",attrs:{"language":"js"}},[_vm._v(" "+_vm._s(_vm.resLaunch.data)+" ")])],1):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"align-self":"center","cols":"12"}},[_c('b-button',{staticClass:"mr-1",on:{"click":function($event){_vm.openModal = false}}},[_vm._v("Close")]),(_vm.resLaunch.data)?_c('div',[(_vm.resLaunch.data.launch)?_c('b-button',{attrs:{"variant":"primary","href":_vm.resLaunch.data.launch,"target":"_blank"}},[_vm._v("Open Game")]):_vm._e()],1):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }