<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col md="6"></b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button
          @click="openModal = !openModal"
          class="text-nowrap"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap">{{ $t('labels.new_server') }}</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- table -->
    <span v-show="loadingProduct" style="color:red;">{{ $t('labels.loadingProduct') }}</span>
    <v-select class="mb-1" v-model="productId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="productOptions" label="productId" :reduce="(o) => o.productId" placeholder="Select product">
    </v-select>
    <vue-good-table
      :columns="columns"
      :rows="getServer"
      :sort-options="{ enabled: true }"
      :search-options="{
        enabled: true,
        externalQuery: searchQuery,
      }"
      styleClass="vgt-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'actions'">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="importData(props.row.name)">
              <feather-icon icon="Icon" />
              <span class="align-middle ml-50">{{$t('labels.importData')}}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="findServer(props.row)">
              <feather-icon icon="Icon" />
              <span class="align-middle ml-50">{{$t('labels.show')}}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="removeServer(props.row)">
              <feather-icon icon="Icon" />
              <span class="align-middle ml-50">{{$t('labels.delete')}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </vue-good-table>
    <section style="padding: 30px 0 0px 0px;">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              first-number
              @change="getServer"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" /> 
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    <!-- Modal add -->
    <b-modal no-close-on-backdrop v-model="openModal" hide-footer modal-class="modal-primary" centered :title="$t('labels.new_server')" size="lg">
      <validation-observer ref="Form">
        <b-form class="p-2" @submit.prevent="addServer">
          <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required">
                  <b-form-group label="URL" class="my-1">
                    <b-form-input v-model="formData.name"/>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
          </b-row>
          <hr>
          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button
              :variant="'success'"
              class="mr-2"
              type="submit">
              <feather-icon :icon="'PlusIcon'" class="mr-50"/>
              <span class="align-middle">Agregar</span>
            </b-button>
        </div>
        </b-form>
      </validation-observer>
    </b-modal>
     <!-- Modal update -->
     <b-modal no-close-on-backdrop v-model="openEditModal" hide-footer modal-class="modal-primary" centered :title="$t('update_server')" size="lg">
      <validation-observer ref="Form">
        <b-form class="p-2" @submit.prevent="updateServer">
          <b-row>
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required">
                  <b-form-group label="URL" class="my-1">
                    <b-form-input name="id" v-model="obj._id" hidden/>
                    <b-form-input v-model="obj.name"/>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
          </b-row>
          <hr>
          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button
              :variant="'success'"
              class="mr-2"
              type="submit">
              <feather-icon :icon="'PlusIcon'" class="mr-50"/>
              <span class="align-middle">Agregar</span>
            </b-button>
        </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>
<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,BForm,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive';
import { required } from "@validations";
import serverModule from "@/store/configuration";
import productStoreModule from "@/store/products";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  setup() {
    // Register module
    if (!store.hasModule('serverModule')) store.registerModule('serverModule', serverModule)
    if (!store.hasModule('productsModule')) store.registerModule('productsModule', productStoreModule)
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate
  },
  data: () => ({
    customerOptions: [],
    showPassword: false,
    required,
    openModal: false,
    openEditModal: false,
    loadingData: false,
    productOptions: [],
    productId: 0,
    loadingProduct: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    obj: {},
    formData: {
      name: "",
    },
    columns: [
      { label: "Name", field: "name" },
      { label: "CreatedAt", field: "createdAt" },
      { label: "Actions", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    timeOutSearchCustomer: null,
  }),
  watch: {
    searchQuery() {
      this.showServer();
    },
  },
  computed: {
    getServer() {
      return [...Array.from(this.$store.state.serverModule.obj)];
    },
  },
  methods: {
    async addServer(){
      if (!await this.$refs.Form.validate()) {
        return
      }
      try {
        this.loadingData = true;
        await this.$store.dispatch('serverModule/addServer', this.formData)
        .then(() => {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "Server added successfully",
              icon: "CheckCircleIcon",
              variant: "success"
            },
          });
          this.showServer()
          this.loadingData = false;
          this.openModal = false;
          this.formData = {
            name: "",
          };
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add user admin",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async updateServer(){
      if (!await this.$refs.Form.validate()) {
        return
      }
      try {
        this.loadingData = true;
        await this.$store.dispatch('serverModule/updateServer', this.obj)
        .then(() => {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "Server updated successfully",
              icon: "CheckCircleIcon",
              variant: "success"
            },
          });
          this.showServer()
          this.loadingData = false;
          this.openEditModal = false;
          this.formData = {
            name: "",
          };
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add user admin",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async showServer(page = 1) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("serverModule/getServers", {
          q: this.searchQuery,
          paginate: {
            page: page,
            perPage: this.per_page,
            sortDesc: true 
          } 
      });
      this.total = res.data.length;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    findServer(o){
      this.obj = JSON.parse(JSON.stringify(o) )
      this.openEditModal = true
    },
    async removeServer(o) {
      try {
        this.loadingData = true;
        await this.$store.dispatch('serverModule/removeServer', { id: o._id })
        .then(() => {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "Server remove successfully",
              icon: "CheckCircleIcon",
              variant: "success"
            },
          });
          this.showServer()
          this.loadingData = false;
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add user admin",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async importData(url) {
      try {
        this.loadingProduct = true;
        const productId = this.productId
        const concatString = productId === 0 ? url : `${url}?product=${productId}`
        return await this.$store.dispatch('serverModule/importData', {
          url: concatString,
        })
        .then(() => {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: "Server import successfully",
              icon: "CheckCircleIcon",
              variant: "success"
            },
          });
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error import server",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingProduct = false;
      }
    },
    async getProduct() {
      const res = await this.$store.dispatch("productsModule/getProducts");
      this.productOptions = [...Array.from(res.data)];
    },
  },
  mounted() {
    this.showServer();
    this.getProduct();
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}
.form-group {
    margin-bottom: 0 !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

