import axios from 'axios';

export default {
    namespaced: true,
    state: {
        files: [], // Almacenará la lista de archivos obtenidos desde S3
    },
    getters: {
        // Getter para obtener todos los archivos
        allFiles(state) {
            return state.files;
        },
    },
    mutations: {
        // Mutación para actualizar la lista de archivos en el estado
        SET_FILES(state, payload) {
            state.files = payload;
        },
    },
    actions: {
        // Acción para listar todos los archivos desde S3
        listFiles({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/files')
                    .then(response => {
                        commit('SET_FILES', response.data);
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },

        // Acción para subir un nuevo archivo a S3
        uploadFile({ commit }, {fileData, path}) {
            const formData = new FormData();
            formData.append('file', fileData);
            formData.append('path', path);

            return new Promise((resolve, reject) => {
                axios.post('/files', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
            });
        },

        // Acción para eliminar un archivo de S3
        deleteFile({ commit }, fileKey) {
            return new Promise((resolve, reject) => {
                axios.delete(`/files/${fileKey}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },

        // Acción para obtener un archivo específico desde S3
        getFile({ commit }, fileKey) {
            return new Promise((resolve, reject) => {
                axios.get(`/files/${fileKey}`)
                    .then(response => {
                        resolve(response.data); // Retorna el contenido del archivo
                    })
                    .catch(error => reject(error));
            });
        },

        // Acción para actualizar o insertar un archivo en S3 (upsert)
        upsertFile({ commit }, { fileKey, fileData }) {
            const formData = new FormData();
            formData.append('file', fileData);

            return new Promise((resolve, reject) => {
                axios.put(`/files/${fileKey}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    resolve(response);
                })
                .catch(error => reject(error));
            });
        },
    },
};
