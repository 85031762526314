<template>
  <b-card>
    <div class="mb-1">
      <!-- input filter -->
      <b-row>
        <!-- ROUNDID -->
        <b-col md="4">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y H:i',
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              class="form-control"
              :config="{
                altInput: true,
                altFormat: 'd-m-Y H:i',
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Buscador">
            <b-form-input
              v-model="filterSelect.q"
              class="d-inline-block mr-1"
              :placeholder="$t('labels.search')"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group :label="$t('labels.products')">
            <v-select
              v-model="filterSelect.selectProduct"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              label="name"
              :reduce="(product) => product.productId"
              :loading="isloadingDataProduct"
              placeholder="All"
            />
          </b-form-group>
        </b-col>

        <!-- TYPE: CREDIT, DEBIT -->
        <b-col md="4">
          <b-form-group :label="$t('labels.typeTransaction')">
            <v-select
              v-model="filterSelect.selectType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              placeholder="All"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group :label="$t('labels.gameId')">
            <b-form-input
              v-model="filterSelect.gameId"
              class="d-inline-block mr-1"
              :placeholder="$t('labels.gameId')"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button
            :disabled="loadingData"
            @click="inputSelect()"
            class="text-nowrap"
            variant="primary"
          >
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{
              $t("labels.search")
            }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- table -->
    <b-overlay :show="loadingData" variant="transparent">
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :totalRows="totalRows"
        @on-sort-change="onSortChange"
        :pagination-options="{ enabled: true }"
        :sort-options="{ enabled: false }"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'productId'">
            <div>{{ props.row.response.connection.productId }}</div>
          </div>
          <div v-if="props.column.field == 'gameId'">
            <div>{{ props.row.response.connection.gameId }}</div>
          </div>
          <div v-if="props.column.field == 'route'">
            <div>{{ props.row.route }}</div>
          </div>
          <div v-if="props.column.field == 'type'">
            <div>{{ props.row.response.connection.operationType }}</div>
          </div>
          <div v-if="props.column.field == 'roundId'">
            <div>{{ props.row.request | showRoundId }}</div>
          </div>
          <div v-else-if="props.column.field == 'createdAt'">
            <div>{{ props.row.createdAt | formatDate }}</div>
          </div>
          <div v-else-if="props.column.field == 'request'">
            <!-- show modal v-b-modal -->
            <div class="d-flex justify-content-center">
              <b-button
                class="mr-1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal="'modalRequest' + props.row._id"
                size="sm"
                variant="outline-primary"
              >
                Show data
              </b-button>
            </div>
            <!-- Request -->
            <b-modal
              size="xl"
              :id="'modalRequest' + props.row._id"
              title="Request / Response"
              cancel-variant="outline-secondary"
              scrollable
            >
              <div>
                <div class="d-flex justify-content-between flex-wrap">
                  <h5>Request</h5>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="mb-1"
                    @click="copyToClipboard(props.row.request)"
                  >
                    Copy
                  </b-button>
                </div>

                <prism language="javascript" copy>
                  {{ props.row.request | pretty }}
                </prism>
              </div>
              <div class="mt-2">
                <div class="d-flex justify-content-between flex-wrap">
                  <h5>Response</h5>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="mb-1"
                    @click="copyToClipboard(props.row.response.response)"
                  >
                    Copy
                  </b-button>
                </div>
                <prism language="javascript" copy>
                  {{ props.row.response.response | pretty }}
                </prism>
              </div>
            </b-modal>
            <b-modal
              size="xl"
              :id="'modalResponse' + props.row._id"
              title="PopUp Response"
              cancel-variant="outline-secondary"
              scrollable
            >
              <prism language="javascript" code="true">
                {{ props.row.response | pretty }}
              </prism>
            </b-modal>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="serverParams.perPage"
                :options="pages"
                class="mx-1"
                @input="onPerPageChange"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="serverParams.perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="onPageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from "@core/utils/filter";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    Prism,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  // add filters
  filters: {
    formatDate,
    pretty: function (value) {
      return value;
    },
    showRoundId: function (request) {
      const possileVal = [
        "playId",
        "referenceId",
        "transactionId",
        "transaction_id",
        "id_bet",
        "round_id",
        "bet_code",
        "roundId",
        "reference",
      ];
      let roundId = null;
      possileVal.forEach((val) => {
        if (request[val]) {
          roundId = request[val];
        }
      });
      return roundId;
    },
  },
  data() {
    return {
      showFilter: false,
      loadingData: false,
      isloadingDataProduct: false,
      pages: [20, 40, 60, 100, 200, 500, 1000, 2000],
      columns: [
        { label: "ProductId", field: "productId" },
        { label: "GameId", field: "gameId" },
        { label: "Route", field: "route" },
        { label: "Type", field: "type" },
        { label: "Request/Response", field: "request" },
        { label: "Created At", field: "createdAt" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        perPage: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      typeOptions: [
        "LAUNCH",
        "BALANCE",
        "DEBIT",
        "CREDIT",
        "ROLLBACK",
        "QUERY",
        "ERROR",
      ],
      filterSelect: {
        dateTo: new Date(),
        dateFrom: new Date(),
        q: "",
        selectProduct: "",
        selectType: "",
        gameId: "",
      },
      timeout: null,
    };
  },
  watch: {
    showFilter(val) {
      if (val) {
        // this.getCustomer();
        // this.getProducts();
      }
    },
  },
  methods: {
    onSortChange(params) {
      console.log(params);
      this.serverParams.sort = `${params[0]?.type == "asc" ? "" : "-"}${
        params[0]?.field
      }`;
      this.getLogsV2();
    },
    copyToClipboard(text) {
      text = JSON.stringify(text, null, 2);
      navigator.clipboard.writeText(text);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Copied to clipboard",
          icon: "CopyIcon",
          variant: "success",
        },
      });
    },

    async getLogsV2() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("reports/getLogsV2", {
          ...this.filterSelect,
          ...this.serverParams,
        });
        this.rows = res.data.data;
        this.totalRows = res.data.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching log connections",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    fillZero(number, length) {
      var my_string = "" + number;
      while (my_string.length < length) {
        my_string = "0" + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getLogsV2();
    },
    onPerPageChange(perPage) {
      this.serverParams.page = 1;
      this.serverParams.perPage = perPage;
      this.getLogsV2();
    },
    inputSelect() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.serverParams.page = 1;
        this.getLogsV2();
      }, 500);
    },
    async getCustomer() {
      const res = await this.$store.dispatch("reports/getCustomer");
      this.customerOptions = res.data;
    },
    async getProducts() {
      try {
        this.isloadingDataProduct = true;
        const res = await this.$store.dispatch("reports/getProducts");
        this.productsOptions = res.data;
        this.isloadingDataProduct = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isloadingDataProduct = false;
      }
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    // this.getLogsV2();
    this.getProducts();
  },
  created() {
    var today = new Date();
    var date = new Date();
    // 1 año
    var dateOffset = 24 * 60 * 60 * 1000 * 365;
    date.setTime(date.getTime() - dateOffset);
    date.get;
    // date from 00:00:00
    this.filterSelect.dateFrom =
      date.getFullYear() +
      "-" +
      this.fillZero(parseInt(date.getMonth()) + 1, 2) +
      "-" +
      this.fillZero(date.getDate(), 2) +
      " " +
      this.fillZero(0, 2) +
      ":" +
      this.fillZero(0, 2);
    // hour 23:59:59
    this.filterSelect.dateTo =
      today.getFullYear() +
      "-" +
      this.fillZero(parseInt(today.getMonth()) + 1, 2) +
      "-" +
      this.fillZero(today.getDate(), 2) +
      " " +
      this.fillZero(23, 2) +
      ":" +
      this.fillZero(59, 2);
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
