<template>
  <b-card>
    <b-row>
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <!-- <b-button v-if="provider.length > 0 && tag" @click="addGames" variant="success" class="mr-2" type="submit">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Save</span>
        </b-button> -->

        <!-- <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('labels.search')"
          /> -->

        <b-button @click="addTagModal = true" variant="primary" class="mr-2 d-flex" type="submit">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Agregar Tag</span>
        </b-button>
      </b-col>

      <b-col md="4">
        <label>Product
        <span class="text-danger">*</span>
        </label>
        <b-overlay :show="loading" rounded="lg" opacity="0.6">
          <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="secondary" />
              <b-spinner type="grow" variant="dark" />
              <b-spinner small type="grow" variant="secondary" />
              <span class="sr-only">Please wait...</span>
            </div>
          </template>

          <v-select multiple v-model="provider" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="providersOptions" label="name" @input="inputProvider" :reduce="(item) => item._id"
            placeholder="Provider">
          </v-select>
        </b-overlay>
      </b-col>

      <b-col md="4">
        <label>Marcas</label>
        <b-overlay :show="loadingDataBrands" rounded="lg" opacity="0.6">
          <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="secondary" />
              <b-spinner type="grow" variant="dark" />
              <b-spinner small type="grow" variant="secondary" />
              <span class="sr-only">Please wait...</span>
            </div>
          </template>

          <v-select class="mb-1" v-model="brandSelectId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="brandsOptions" label="name" :reduce="(brand) => brand._id" @input="brandSelectInput"
            placeholder="Select brand">
          </v-select>
        </b-overlay>
      </b-col>

      <b-col v-if="provider.length > 0" md="4">
        <label>Status <span class="text-danger">*</span></label>
        <v-select v-model="selected" @change="status" :options="optionsStatus" label="text"
          :reduce="(item) => item.value" />
      </b-col>

      <b-col v-if="provider.length > 0" md="4">
        <label>Tag <span class="text-danger">*</span></label>
        <v-select v-if="provider.length > 0" v-model="tag" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="tagsOptions" label="name" @input="input" :reduce="(item) => item._id" placeholder="Select tag">
        </v-select>
      </b-col>

      <b-col md="4" class="">
        <label>Search</label>
        <b-form-input v-model="search" class="d-inline-block mr-1" placeholder="Search..." />
      </b-col>

    </b-row>
    <b-overlay :show="loading" variant="transparent" v-if="showGames">
      <div class="row">
        <div class="col-12 mt-2">
          <h3>Games</h3>
          <b-row>
            <b-col md="4" v-for="item in games" :key="item.gameId">
              <b-form-checkbox v-model="selectedGames" @change="addGames({gameId: item.gameId, idGame: item._id})" :value="item._id" inline>
                <div class="col">
                  <img :src="item.url_image" :title="item.gameId" width="50" height="50">
                  <div class="col" style="padding: 0 !important;">
                    {{ item.gameName }}
                  </div>
                  <br>
                  <br>
                </div>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <section style="padding: 30px 0 0px 0px;">
            <b-row>
              <b-col cols="12">
                <b-pagination v-model="current_page" :total-rows="total" :per-page="per_page" first-number
                  @change="getGames(provider, $event, tag)" align="right" last-number prev-class="prev-item"
                  next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </section>
        </div>
      </div>
    </b-overlay>
    <b-modal v-model="addTagModal" hide-footer modal-class="modal-primary" centered :title="'Create Tag'">
      <validation-observer ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="saveTag">
          <validation-provider name="name">
            <b-form-group label="Name">
              <b-form-input v-model="formData.name" />
            </b-form-group>
          </validation-provider>
          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button :variant="'success'" class="mr-2" type="submit">
              <feather-icon :icon="'PlusIcon'" class="mr-50" />
              <span class="align-middle">Add</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BForm,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BSpinner,
  BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBToggle,
    VBModal,
    BCardText,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BSpinner,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BFormCheckboxGroup
  },
  data() {
    return {
      selectedGames: [], // Must be an array reference!
      allGames: [],
      models: [],
      myGames: [],
      current_page: 1,
      total: 0,
      per_page: 20,
      formData: {
        name: '',
      },
      provider: [],
      providersOptions: [],
      addTagModal: false,
      tagsOptions: [],
      loading: false,
      tag: null,
      search: null,
      timeOutSearch: null,
      tags: [],
      games: [],
      tagSelected: [],
      showGames: false,
      selected: null,
      optionsStatus: [
        { value: true, text: 'Active' },
        { value: false, text: 'Unactive' },
      ],
      loadingDataBrands: false,
      brandSelectId: null,
      brandsOptions: [],
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeOutSearch)
      this.timeOutSearch = setTimeout(() => {
        this.getGames(this.provider, this.current_page, this.tag)
      }, 800)
    },
  },
  methods: {
    status(status) {
      this.getTags(status)
    },
    modelGame(item) {
      if (this.tagSelected.indexOf(item) === -1) {
        this.tagSelected.push(item);
      } else {
        this.tagSelected.splice(this.tagSelected.indexOf(item), 1);
      }
    },
    brandSelectInput(val) {
      // colocar minuscula
      const brandId = val ? val.toLowerCase() : null
      this.getGames(this.provider, this.current_page, this.tag, brandId)
    },
    async getBrands() {
      try {
        this.loadingDataBrands = true;
        const dataBrands = await this.$store.dispatch("brandModule/index", this.provider[0]);

        this.brandsOptions = dataBrands.data?.brands
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching brands list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataBrands = false;
      }
    },
    inputProvider(item) {
      if (item.length == 0) {
        this.showGames = false;
        this.current_page = 1
        this.total = 0
        this.per_page = 10
      }
      this.getBrands()
      this.getGames(item)
    },
    input() {
      if (this.tag) {
        this.showGames = true
        this.getGames(this.provider, this.current_page, this.tag)
        this.mysGames()
      } else if (this.tag == null) {
        this.total = 0
        this.games = []
      }
    },
    getTags(status = true) {
      this.$store.dispatch('tagsModule/getTags', { status: status })
        .then(response => {
          this.tagsOptions = response
        })
        .catch(error => {
          console.log(error)
        })
    },
    getGames(gameId = [], page = 1, tag = null, search = null) {
      this.$store.dispatch('tagsModule/getGames', { 
        gameId: gameId, 
        page: page, 
        tagId: tag, 
        brandId: this.brandSelectId,
        search: this.search, 
        limit: this.per_page
       })
        .then(response => {
          this.allGames = response.allGames
          this.games = response.data
          this.total = response.total
          if (response.tagGames != null) {
            this.myGames = response.tagGames.games
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getProducts() {
      this.$store.dispatch('tagsModule/getProducts')
        .then(response => {
          this.providersOptions = response
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveTag() {
      this.$store.dispatch('tagsModule/storeTag', this.formData)
        .then(() => {
          this.addTagModal = false
          this.formData = {
            name: '',
          }
          this.getTags()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              variant: 'success create tag',
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    addGames({gameId, idGame}) {
      const status = this.selectedGames.indexOf(idGame) !== -1

      this.$store.dispatch('tagsModule/addTagGames', {
        tagId: this.tag,
        data: {
          status,
          gameId,
          idGame,
          providers: this.provider
        }
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
        .catch(error => {
          console.log(error)
        })
    },
    mysGames(gameId = []) {
      this.$store.dispatch('tagsModule/getGames', { gameId: gameId, tagId: this.tag })
        .then(response => {
          this.selectedGames = response.selectedGames.games
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  mounted() {
    this.getTags()
    this.getProducts()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.scrollable {
  height: 300px !important;
  overflow-y: scroll !important;
}

.list-group {
  flex-direction: row !important;
}
</style>
