import reports from './reports'
export default [
    {
        path: '/pages/product/list',
        name: 'products-list',
        component: () => import('@/views/pagesIntegrations/products'),
        meta: {
            pageTitle: 'Products',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/product/config/:id',
        name: 'product.config',
        component: () => import('@/views/pagesIntegrations/products/config'),
        meta: {
            pageTitle: 'Product Config',
            breadcrumb: [
                {
                    text: 'Config',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/games/list/product/:producSelectId?/:brandId?',
        name: 'games-list',
        component: () => import('@/views/pagesIntegrations/games'),
        meta: {
            pageTitle: 'Games',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/customer/list',
        name: 'customer-list',
        component: () => import('@/views/pagesIntegrations/customer'),
        meta: {
            pageTitle: 'Customer',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/users/list',
        name: 'users-list',
        component: () => import('@/views/pagesIntegrations/users'),
        meta: {
            pageTitle: 'Users',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/players/list',
        name: 'players-list',
        component: () => import('@/views/pagesIntegrations/players'),
        meta: {
            pageTitle: 'Players',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/tags',
        name: 'tags',
        component: () => import('@/views/pagesIntegrations/tags/list/index'),
        meta: {
            pageTitle: 'Tags',
            breadcrumb: [
                {
                    text: 'List Tags',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/currencies',
        name: 'currencies-list',
        component: () => import('@/views/pagesIntegrations/currencies'),
        meta: {
            pageTitle: 'Currencies',
            breadcrumb: [
                {
                    text: 'List Currencies',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/tags/games',
        name: 'tags.games',
        component: () => import('@/views/pagesIntegrations/tags'),
        meta: {
            pageTitle: 'Tags games',
            breadcrumb: [
                {
                    text: 'List Tags Games',
                    active: true,
                },
            ],
        },
    },
    ...reports,
    
    // synchronizeReports
    {
        path: '/pages/synchronizeReports',
        name: 'synchronizeReports',
        component: () => import('@/views/pagesIntegrations/synchronizeReports'),
        meta: {
            pageTitle: 'Synchronize Reports',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },

    // C:\Proyectos\BC\whish-gateway-admin\src\views\pagesIntegrations\ticket_sportsbook\index.vue
    {
        path: '/pages/ticket_sportsbook',
        name: 'apps-ticket-sportsbook',
        component: () => import('@/views/pagesIntegrations/ticket_sportsbook'),
        meta: {
            pageTitle: 'Ticket Sportsbook',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/betprime',
        name: 'apps-betprime',
        component: () => import('@/views/pagesIntegrations/betcore_banners'),
        meta: {
            pageTitle: 'BetPrime',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/pages/brands/list',
        name: 'apps-brands',
        component: () => import('@/views/pagesIntegrations/brands/index.vue'),
    },
    {
        path: '/pages/games/categories',
        name: 'apps-games-categories',
        component: () => import('@/views/pagesIntegrations/games/categories/index.vue'),
    },

    // user Edit
    {
        path: '/account/user',
        name: 'account-user-edit',
        component: () => import('@/views/pagesIntegrations/accounts/UserEdit.vue'),
    },
    // Configuration
    {
        path: '/configuration/server/list',
        name: 'server-list',
        component: () => import('@/views/apps/configuration/index'),
        meta: {
            pageTitle: 'Server',
            breadcrumb: [
                {
                    text: 'list',
                    active: true,
                },
            ],
        },
    },
    
]