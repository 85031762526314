<template>
  <div>
  
      <b-overlay :show="loadingData" variant="transparent" no-wrap />

      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>

            <b-col md="12">
              <b-form-group :label="$t('labels.password')">
                <validation-provider
                    #default="{ errors }"
                    name="password"
                    :rules="{ required: true, min: 8 }"
                >
                  <b-form-input
                      type="password"
                      v-model="formData.password"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('labels.password')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-1">
              <b-form-group :label="$t('labels.confirmPassword')">
                <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    :rules="{ required: true, confirmed: 'password'}"
                >
                  <b-form-input
                      type="password"
                      v-model="formData.confirm_password"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('labels.confirmPassword')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

          <hr>

          <div class="d-flex align-items-center justify-content-between mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              {{$t('buttons.send')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
  </div>
</template>

<script>
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, VBModal, BCard, BOverlay
} from 'bootstrap-vue'
import { BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions  } from 'vuex'

export default {
  name: "userChangePassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BCard,
    vSelect,
    VBModal,
    BOverlay,
    ToastificationContent
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data(){
    return{
      required,
      modalShow : false,
      loadingData : false,
      formData: {
        password: null,
        confirm_password: null,
      },
    }
  },

  methods: {
    ...mapActions('usersModule',['changePassword']),

    async validationForm() {
      this.$refs.simpleRules.validate()
          .then(async (success) => {
            if (success) {
              try {
                this.loadingData = true
                let data = JSON.parse(JSON.stringify(this.formData))
                delete data.confirm_password
                data.id = this.userData._id
                await this.changePassword(data)
                this.modalShow = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('labels.userPasswordChanged'),
                    icon: "CheckCircleIcon",
                    variant: "success",
                  },
                });
                this.$refs.simpleRules.reset()
                this.resetFormData()
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('labels.errorChangePassword'),
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                    text: `${error.message}`,
                  },
                });
                console.log(error)
              }finally{
                this.loadingData = false
              }
            }
          })
          .catch((error) => {
            this.$swal({
              title: this.$t('labels.error'),
              text: error.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
    },

    resetFormData(){
      this.formData = {
        password: null,
        confirm_password: null,
      }
    },

  }

}
</script>

<style scoped>

</style>