

<template>
    <div v-if="openModal" class="mt-2">
        <b-overlay :show="isLoadingGetField" variant="transparent" no-wrap />
        <b-modal v-model="openModal" hide-footer modal-class="modal-primary" centered
            :title="`Agregar imagenes a ${gameSelected.gameName}`" size="lg">
            <b-row>
                <b-col class="d-flex justify-content-end">
                    <b-button @click="openModalFieldImage = true" class="mr-2" size="sm" variant="outline-danger">
                        <feather-icon :icon="'PlusIcon'" class="mr-50" />
                        <span class="align-middle">Campos</span>
                    </b-button>

                    <b-modal no-close-on-backdrop v-model="openModalFieldImage" hide-footer
                        modal-class="modal-primary-field" centered title="Campos de imagenes" size="sm">
                        <b-overlay :show="isLoadingGetField" variant="transparent" no-wrap />

                        <b-form @submit.prevent="storeFieldsImages" class="mb-2">
                            <b-row>
                                <b-col>
                                    <b-form-group label-for="key">
                                    <label> 
                                        Key
                                        <small class="text-danger">*</small>
                                    </label>
                                        <b-form-input id="key" v-model="formDataField.key" />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <b-form-group label="Description" label-for="description">
                                        <b-form-input id="description" v-model="formDataField.description" />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <b-button type="submit" variant="primary" block size="sm"
                                        :disabled="isLoadingCreateField">
                                        <feather-icon v-if="!isLoadingCreateField" :icon="'PlusIcon'" class="mr-50" />
                                        <b-spinner v-if="isLoadingCreateField" small class="mr-50" />
                                        <span class="align-middle">Agregar</span>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>


                        <b-list-group v-if="listFields.length > 0">
                            <b-list-group-item v-for="(items, index) in listFields" :key="index"
                                class="d-flex justify-content-between align-items-center">
                                <div class="flex-column align-items-start">

                                    <div class="d-flex w-100 justify-content-between">
                                        <span>{{ items.key }}</span>
                                    </div>

                                    <small>{{ items.description }}</small>

                                </div>
                                <b-button @click="deleteFieldImage(items)" size="sm" variant="outline-danger">
                                    <feather-icon :icon="'TrashIcon'" />
                                </b-button>

                            </b-list-group-item>

                        </b-list-group>
                        <div v-else class="text-center">
                            <small>No hay campos</small>
                        </div>

                    </b-modal>

                </b-col>
            </b-row>


            <b-row>
                <b-col 
                cols="12"
                    v-for="(items, index) in listFields" :key="index"
                >
                    <b-form-group :label="items.key" class="my-1">
                        <b-form-input 
                            :id="items.key" 
                            :name="items.key" 
                            :placeholder="items.key" 
                            v-model="formDataImageGames[items.key]"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <hr>


            <div class="d-flex align-items-center justify-content-between mt-2">
                <b-button 
                    @click="updateImagesGames"
                :variant="'success'" class="mr-2" type="submit">
                    <feather-icon :icon="'PlusIcon'" class="mr-50" />
                    <span class="align-middle">Actualizar</span>
                </b-button>
            </div>
        </b-modal>

    </div>
</template>
  
  
<script>
import {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BOverlay,
    BFormFile,
    BImg,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaDash } from "@validations";
import Ripple from "vue-ripple-directive";
import gameStoreModule from "@/store/games";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    name: "UploadImegesGames",
    components: {
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        ValidationProvider,
        ValidationObserver,
        BFormCheckbox,
        BOverlay,
        BFormFile,
        BImg,
        BRow,
        BCol,
        BListGroup,
        BListGroupItem,
        BSpinner,

        ToastificationContent,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required,
            modeEdit: false,
            loadingData: false,
            url_image: null,
            openModal: false,
            gameSelected: null,
            openModalFieldImage: false,
            isLoadingGetField: false,
            listFields: [],
            isLoadingCreateField: false,
            formDataField: {
                key: "",
                description: "",
            },
            formDataImageGames: {},

        };
    },
    watch: {
        openModalFieldImage(val) {
            if (val) {
                this.getField();
            }
        },
        openModal(val) {
            if (val) {
                this.getField();
            }
        },
    },

    methods: {
        async convertFormData(data) {
      const fd = new FormData();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            // tener en cuenta los tags
            if (key === "tags") {
              data.tags.forEach(tag => {
                fd.append("tags[]", tag);
              });
            } else if (key === "imageCollection") {
              Object.keys(data.imageCollection).forEach(keyImage => {
                fd.append(`imageCollection[${keyImage}]`, data.imageCollection[keyImage]);
              });
            } else {
              fd.append(key, data[key]);
            }
          }
        }
        return fd;
    },
        async updateImagesGames() {
            try {
                this.loadingData = true;

                this.formDataImageGames = Object.keys(this.listFields).reduce((obj, key) => {
                    obj[this.listFields[key].key] = this.formDataImageGames[this.listFields[key].key] || "";
                    return obj;
                }, {});
                this.gameSelected.imageCollection = this.formDataImageGames
                const gameDataFormat = {
                    gameID: this.gameSelected.gameId,
                    product: this.gameSelected.product._id,

                    imageCollection: this.gameSelected.imageCollection,
                }
                
               const gameData = await this.convertFormData(gameDataFormat);
               const gameDataOld = {
                gameID: this.gameSelected.gameId,
                product: this.gameSelected.product._id
               }

                const res = await this.$store.dispatch("gamesModule/updateGame",{gameData, gameDataOld});

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Imagenes actualizadas correctamente",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });

                this.$emit('getGames')
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al actualizar las imagenes",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingData = false;
            }
        },
        // Save game
        async saveGame() {
            try {
                const gameData = await this.convertFormData(this.game);

                await this.$store.dispatch("gamesModule/addGame", { gameData, product: this.game.product });

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Game added successfully",
                        icon: "CheckCircleIcon",
                        variant: "success"
                    },
                });
            } catch (error) {
                console.log(error.response.data.message);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error adding game add",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: `${error.response.data.message}`,
                    },
                });
            } finally {
                this.loadingData = false;
            }
        },
        // Update game
        async updateGame() {
            try {
                this.loadingData = true;
                this.game.product = this.producSelectId
                // Esto porque hay disparidad en los campos que se recibe en el backend
                this.game.gameId = this.game.gameID

                // conertir form data 
                const gameData = await this.convertFormData(this.game);

                await this.$store.dispatch("gamesModule/updateGame", { gameData, gameDataOld: this.gameOld });
                this.modeEdit = false
                this.$emit("hiddentSidebar");
                if (this.$route.params.producSelectId == this.producSelectId) {
                    this.$emit('getGames')
                }
                if (this.$route.params.producSelectId !== this.producSelectId) {
                    this.$router.push({ params: { producSelectId: this.producSelectId } })
                }
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Game update successfully",
                        icon: "CheckCircleIcon",
                        variant: "success"
                    },
                });
            } catch (error) {
                console.log(error.response.data.message);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error update games add",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: `${error.response.data.message}`,
                    },
                });
            } finally {
                this.loadingData = false;
            }
        },
        //---Validate State Fields
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        // fetch tagsOptions 
        async fetchTagsOptions() {
            this.tagsOptions = await this.$store.dispatch("gamesModule/fetchTagsOptions");
        },
        handleFileUpload(e) {
            this.game.url_image = e.target.files[0];
            this.url_image = URL.createObjectURL(this.game.url_image);
        },
        showModalUploadImages(game) {
            console.log(game);
            this.gameSelected = game
            this.openModal = true
        },
        deleteFieldImage(items) {
            this.$swal({
                title: "Por favor confirme",
                text: `Esta seguro de eliminar el campo ${items.key}?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Si",
                cancelButtonText: "No",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const res = await this.$store.dispatch("gamesModule/deleteFieldsImages", items._id);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Campo eliminado correctamente',
                                icon: "CheckCircleIcon",
                                variant: "success",
                            },
                        });

                        this.getField();
                    } catch (error) {
                        console.log(error);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error al eliminar el campo",
                                icon: "AlertTriangleIcon",
                                variant: "danger",
                            },
                        });
                    }
                }
            });
        },
        async getField() {
            try {
                this.isLoadingGetField = true;
                const res = await this.$store.dispatch("gamesModule/getFieldsImages");
                this.listFields = res.data;

                this.formDataImageGames = Object.keys(this.listFields).reduce((obj, key) => {
                    if(this.gameSelected.imageCollection){
                        obj[this.listFields[key].key] = this.gameSelected.imageCollection[this.listFields[key].key] || "";
                    }else{
                        obj[this.listFields[key].key] = "";
                    }
                    return obj;
                }, {});
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoadingGetField = false;
            }
        },
        async storeFieldsImages() {
            try {

                // validar que el key no este vacio, no tenga espacios y no tenga caracteres especiales, que no se repita
                if (this.formDataField.key == "") {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "El campo key es requerido",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                }

                if (this.formDataField.key.includes(" ")) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "El campo key no puede tener espacios",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                }

                // validar los caracteres especiales, puede teber guiones, numeros, letras, pisos
                if(!/^[a-zA-Z0-9-_]+$/.test(this.formDataField.key)) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "El campo key no puede tener puntos",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                }

                // validar que el key no se repita
                if (this.listFields.find((item) => item.key == this.formDataField.key)) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "El campo key ya existe",
                            icon: "AlertTriangleIcon",
                            variant: "danger",
                        },
                    });
                    return;
                }



                this.isLoadingCreateField = true;
                const res = await this.$store.dispatch("gamesModule/storeFieldsImages", this.formDataField);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Campos agregados correctamente",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });

                this.formDataField = {
                    key: "",
                    description: "",
                };
                this.getField();
            } catch (error) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al agregar los campos",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.isLoadingCreateField = false;
            }
        },

    },
    created() {
        if (!store.hasModule("gamesModule"))
            store.registerModule("gamesModule", gameStoreModule);
    },
    destroyed() {
        if (store.hasModule("gamesModule"))
            store.unregisterModule("gamesModule");
    },
};
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}

#preview {
    display: flex;
    justify-content: center;
    align-items: center;
}

#preview img {
    max-width: 100%;
    max-height: 150px;
}
</style>
  