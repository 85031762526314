<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">{{ modeEdit ? $t("Edit") : $t("New") }} Product</h5>
    </div>
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(modeEdit ? updateProduct : saveProduct)"
        @reset.prevent="resetForm"
      >
        <!-- <validation-provider
          #default="validationContext"
          name="Product Name"
          rules="required"
        >
          <b-form-group :label="$t('Categories')" label-for="name">
             <v-select
              v-model="product.category"
              label="name"
              :reduce="(item) => item._id"
              :options="categoriesOptions"
              :clearable="false"
              :searchable="false"/>
          </b-form-group>
        </validation-provider> -->

        <!-- <validation-provider
          name="brands"
          rules="required"
        >
          <b-form-group :label="$t('brands')" label-for="brands">
             <v-select
              v-model="product.brands"
              label="name"
              :reduce="(item) => item._id"
              :options="brandsOptions"
              :clearable="false"
              :multiple="true"
              />
          </b-form-group>
        </validation-provider> -->

        <!-- Full Name -->
        <validation-provider
          #default="validationContext"
          name="Product Name"
          rules="required"
        >
          <b-form-group :label="$t('Product Name')" label-for="name">
            <b-form-input
              id="name"
              v-model="product.name"
              autofocus
              :state="getValidationState(validationContext)"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider #default="validationContext" name="Description">
          <b-form-group
            :label="$t('labels.Description')"
            label-for="Description"
          >
            <b-form-input
              id="Description"
              v-model="product.description"
              :state="getValidationState(validationContext)"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider name="Credentials">
          <b-form-group
            :label="$t('labels.Credentials')"
            label-for="credentials"
          >
            <v-jsoneditor
              v-model="product.credentials"
              :plus="true"
              height="350px"
              :options="configOptionsEditJson"
            />
          </b-form-group>
        </validation-provider>
        <br />
        <b-form-group :label="$t('labels.status')" label-for="status">
          <b-form-checkbox
            v-model="product.status"
            id="status"
            class="custom-control-success"
            switch
            inline
          >
            <b>{{ product.status ? "Activo" : "Inactivo" }}</b>
          </b-form-checkbox>
        </b-form-group>

        <div class="mt-2">
          <p>Import Games</p>
          <b-form-group label="Games Api" label-for="isImportGamesApi">
            <b-form-checkbox
              v-model="product.isImportGamesApi"
              id="isImportGamesApi"
              class="custom-control-success"
              switch
              inline
              @change="validateVarImportGames('isImportGamesApi', $event)"
            />
          </b-form-group>
          <b-form-group label="Games Archive" label-for="isImportGamesArchive">
            <b-form-checkbox
              v-model="product.isImportGamesArchive"
              id="isImportGamesArchive"
              class="custom-control-success"
              switch
              inline
              @change="validateVarImportGames('isImportGamesArchive', $event)"
            />
          </b-form-group>
        </div>

        <!-- Form Actions -->
        <div class="d-flex align-items-center justify-content-between mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="modeEdit ? 'success' : 'primary'"
            class="mr-2"
            type="submit"
          >
            <feather-icon
              :icon="modeEdit ? 'RefreshCcwIcon' : 'PlusIcon'"
              class="mr-50"
            />
            <span class="align-middle">{{ modeEdit ? "Update" : "Add" }}</span>
          </b-button>
          <b-button
            @click="$emit('hiddentSidebar')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Cancel</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import productStoreModule from "@/store/products";
import store from "@/store";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import JsonViewer from "vue-json-viewer";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    BFormFile,
    BOverlay,
    ToastificationContent,
    JsonViewer,
    VJsoneditor,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      configOptionsEditJson: {
        mode: "code",
        modes: ["code", "tree"],
        isExpand: true,
        height: "auto",
        navigationBar: true,
      },
      categoriesOptions: [],
      required,
      modeEdit: false,
      loadingData: false,
      uploadTypeOptions: [
        { label: "None", value: 0 },
        { label: "Api", value: 1 },
        { label: "Excel", value: 2 },
      ],
      product: {
        name: "",
        description: "",
        brands: [], //Son Type Array,
        credentials: {}, //Son Type Object
        production: "", //Son Type Array
        uploadType: 0,
        auth: "", //Son Type Array
        bet: "", //Son Type Array
        win: "", //Son Type Array
        rollback: "", //Son Type Array
        settings: "", //Son Type Array
        url_testing: "",
        url_production: "",
        games_api_url: "",
        status: true,
        isgame: false,
        ispayment: false,
        isImportGamesApi: false,
        isImportGamesArchive: false,
        url_document: "",
        file_document: null,
        documentationCredentials: {}, //Son Type Object
      },
    };
  },
  watch: {
    modeEdit(val) {
      if (!val) this.resetForm();
    },
  },
  computed: {
    brandsOptions() {
      return this.$store.getters["brandModule/brandsActives"];
    },
  },
  methods: {
    // Save product
    async saveProduct() {
      try {
        if (this.product.isgame) {
          (this.product.uploadType = 0), (this.product.games_api_url = "");
        }
        this.loadingData = true;
        if (this.product.file_document !== undefined)
          this.product.path_document = localStorage.getItem("path_document");
        await this.$store.dispatch("productsModule/addProduct", this.product);
        this.resetForm();
        this.$emit("getProducts");
        this.$emit("hiddentSidebar");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product added successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        localStorage.removeItem("path_document");
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error adding products add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    // Save product
    async updateProduct() {
      try {
        if (this.product.isgame) {
          (this.product.uploadType = 0), (this.product.games_api_url = "");
        }
        this.loadingData = true;
        if (this.product.file_document !== undefined)
          this.product.path_document = localStorage.getItem("path_document");
        await this.$store.dispatch(
          "productsModule/updateProduct",
          this.product
        );
        this.modeEdit = false;
        this.$emit("hiddentSidebar");
        this.$emit("getProducts");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        localStorage.removeItem("path_document");
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error update products add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    handleFileInput(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const filePath = event.target.result;
        localStorage.setItem("path_document", filePath);
      };
      reader.readAsDataURL(file);
    },
    //---Validate State Fields
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {
      this.product = {
        name: "",
        description: "",
        production: "", //Son Type Array
        uploadType: 0,
        auth: "", //Son Type Array
        bet: "", //Son Type Array
        win: "", //Son Type Array
        rollback: "", //Son Type Array
        settings: "", //Son Type Array
        url_testing: "",
        url_production: "",
        games_api_url: "",
        status: true,
        isgame: false,
        ispayment: false,
        isImportGamesApi: false,
        isImportGamesArchive: false,
        credentials: {},
        documentationCredentials: {},
      };
      this.$refs.refFormObserver.reset();
    },
    async getCategories() {
      const res = await this.$store.dispatch("productsModule/getCategories");
      console.log(res.data);
      this.categoriesOptions = res.data;
    },
    async getBrands() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("brandModule/index");
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching brands list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    validateVarImportGames(identifier, value) {
      if (value && identifier == "isImportGamesApi") {
        this.product.isImportGamesApi = true;
        this.product.isImportGamesArchive = false;
      } else if (value && identifier == "isImportGamesArchive") {
        this.product.isImportGamesArchive = true;
        this.product.isImportGamesApi = false;
      } else {
        this.product.isImportGamesArchive = false;
        this.product.isImportGamesApi = false;
      }
    },
  },
  created() {
    // this.getBrands();
    // this.getCategories();
    // Register module
    if (!store.hasModule("productsModule"))
      store.registerModule("productsModule", productStoreModule);
  },
  destroyed() {
    if (store.hasModule("productsModule"))
      store.unregisterModule("productsModule");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
