
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        users: {
            users: [],
        }
    },
    mutations: {
        GET_USERS(state, payload){
            state.users =  payload
        },
    },
    actions: {
        getUsers({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post('pages/users', payload)
                    .then(response => {
                        commit('GET_USERS', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },

        getUsersPlayers({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.get('/players', {params: payload})
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            })
        },


        blockGame({commit}, {userId, gameId, status}) {
            return new Promise((resolve, reject) => {
                return axios.post(`/gamestatustoplayer/${userId}`, {gameId, status})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        blockProduct({commit}, {userId, product, status}) {
            return new Promise((resolve, reject) => {
                return axios.post(`/productstatustoplayer/${userId}`, {product, status})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addUserAdmin({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/add-user-admin`, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        updateUserAdmin({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/update-user-admin`, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        getCustomer({commit}) {
            return new Promise((resolve, reject) => {
                return axios.get(`/customers`)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        tokenCheck(ctx) {
            return new Promise((resolve, reject) => {
              axios
                .get('tokenCheck')
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
        changePassword({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/users/change/password`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
    getters: {
        userLoged: state => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            if(userData){
                return userData.user
            }else{
                return false
            }
        },
        typeUser: state => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            if(userData){
                return userData.user.typeUser
            }else{
                return false
            }
        },
    }
}
