
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        brands: [],
    },
    getters: {
        brandsActives(state){
            return state.brands.filter((brand) =>  brand.status == true)
        }
    },
    mutations: {
        GET_BRANDS(state, payload){
            state.brands =  payload.brands
        }
    },
    actions: {
        index({commit}, productId) {
            return new Promise((resolve, reject) => {
                return axios.get(`brand`, {params: {productId}})
                    .then(response => {
                        commit('GET_BRANDS', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        store({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/brand/add`, payload)
                    .then(response => {
                        // commit('ADD_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        delete({commit}, id) {
            return new Promise((resolve, reject) => {
                return axios.post(`/brand/delete/${id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        update({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/brand/edit/${payload._id}`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
