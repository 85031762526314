<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end mb-2">
          <div class="w-full mr-2">
            <b-form-input v-model="searchQuery" class="mr-1" :placeholder="$t('labels.search')" />
          </div>

          <b-button @click="addTagModal = true" variant="primary" class="w-full">
            <feather-icon icon="PlusIcon" />
          </b-button>

          <b-modal v-model="addTagModal" hide-footer modal-class="modal-primary" centered
            :title="formData._id ? 'Edit Tag' : 'Add Tag'">
            <validation-observer ref="refFormObserver">
              <b-form class="p-2" @submit.prevent="saveUpdateTag">
                <validation-provider name="name">
                  <b-form-group label="Name">
                    <b-form-input v-model="formData.name" />
                  </b-form-group>
                </validation-provider>
                <div class="d-flex align-items-center justify-content-end mt-2">
                  <b-button :disabled="isLoadingCreate" :variant="'success'" class="mr-2" type="submit">
                    <b-spinner v-if="isLoadingCreate" small />
                    <feather-icon v-else :icon="'SaveIcon'" class="mr-50" />
                    <span class="align-middle">
                      {{ formData._id ? "Actualizar" : "Guardar" }}
                    </span>
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>
      </b-row>
      <b-overlay :show="loading" variant="transparent" no-wrap />
      <b-table :items="itemsComputed" :fields="fields" primary-key="id" show-empty empty-text="No hay tags registrados">
        <template #cell(status)="data">
          <b-badge pill :variant="`light-success`" class="text-capitalize">
            {{ data.item.status ? "Active" : "Unactive" }}
          </b-badge>
        </template>

        <template #cell(games)="data">
          <b-badge class="cursor-pointer" variant="primary" @click="gameInfo(data.item)">
            {{ data.item.games.length }}
          </b-badge>
          <b-badge class="cursor-pointer ml-1" variant="danger" v-if="isDuplicateGames(data.item.games)">
            .
          </b-badge>

        </template>

        <template #cell(createdAt)="data">
          <div>
            <!-- fecha y hora formart  -->
            {{ new Date(data.item.createdAt).toLocaleString() }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="editTag(data.item)">
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteTagConfirm(data.item._id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    <b-modal v-model="openModalGames" hide-footer modal-class="modal-primary" centered :title="'Tag games - ' + tagName"
      size="lg">

      <b-overlay :show="isLoadingModalGames" variant="transparent" no-wrap />

      <b-list-group-item class="d-flex justify-content-between align-items-center w-full">
        <div class="w-full flex align-items-center">
          <div class="w-full d-flex align-items-center">
            <b-form-input v-model="search" class="mr-1" :placeholder="$t('labels.search')" />

            <!-- boton para abrir modal con juegos repetidos -->
            <div class="w-full flex align-items-center">
              <b-button v-if="selectGamesDuplicate.length > 0" @click="openModalGamesDuplicate = true"
                variant="outline-danger" size="sm">
                Duplicate
              </b-button>

              <b-modal v-model="openModalGamesDuplicate" hide-footer modal-class="modal-primary" centered
                :title="'Games duplicate - ' + tagName" size="lg">

                <div v-if="selectGamesDuplicate.length > 0" class="overflow-auto"
                  style="max-height: 400px; min-height: 400px;">

                  <div v-for="(listItem, indexGame) in selectGamesDuplicate" :key="listItem.gameId + indexGame" class="mb-2">

                    <h6 class="mb-1">{{ listItem.gameId }}</h6>

                    <b-list-group-item v-for="(listItemGames, indexItemGame) in listItem.games"
                      :key="listItemGames.gameId + indexItemGame" tag="li"
                      class="d-flex justify-content-between align-items-center w-full">
                      <div class="d-flex align-items-center">

                        <img :src="listItemGames.url_image" :title="listItemGames.gameId" width="50" height="50" />

                        <div class="ml-1 d-flex flex-column">
                          <span>{{ listItemGames.gameName }}</span>
                          <b-badge variant="primary">
                            {{ listItemGames.product.name }}
                          </b-badge>
                        </div>
                      </div>

                      <div>
                        <b-button @click="deleteTagGame(listItemGames.gameId, indexGame, listItemGames._id)"
                          variant="outline-danger" size="sm">
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </div>
                    </b-list-group-item>

                  </div>
                </div>


                <div v-else>
                  <div class="row">
                    <div class="col">
                      <div class="text-center">
                        <h1>No hay juegos duplicados</h1>
                      </div>
                    </div>
                  </div>
                </div>

              </b-modal>


            </div>
          </div>
        </div>

        <!-- header: posicion, accion  -->
        <div class="w-full flex align-items-center justify-content-end">
          <span class="mr-1">Posicion</span>
          <span>Accion</span>
        </div>
      </b-list-group-item>

      <div v-if="selectGames.length > 0" class="overflow-auto" style="max-height: 400px; min-height: 400px;">

        <draggable v-model="selectGames" class="list-group list-group-flush" tag="ul" :item-key="item => item.gameId"
          @change="gamesChagePosition" handle=".handle">
          <transition-group type="transition" name="flip-list">
            <b-list-group-item v-for="(listItem, indexGame) in selectGamesComputed" :key="listItem.gameId + indexGame"
              tag="li" class="d-flex justify-content-between align-items-center w-full">
              <div class="d-flex align-items-center">

                <feather-icon icon="AlignJustifyIcon" class="mr-1 handle cursor-move" size="18" />

                <img :src="listItem.url_image" :title="listItem.gameId" width="50" height="50" />

                <div class="ml-1 d-flex flex-column">
                  <span>{{ listItem.gameName }}</span>
                  <b-badge variant="primary">
                    {{ listItem.product.name }}
                  </b-badge>
                </div>
              </div>
              <div class="flex ml-auto mr-1 row align-items-center justify-content-end">
                <feather-icon class="mr-1 text-primary cursor-pointer" v-if="indexGame > 0"
                  @click="selectGames.splice(0, 0, selectGames.splice(indexGame, 1)[0])" icon="ChevronsUpIcon" />


                <b-form-input type="number" :value="indexGame + 1" style="width: 50px" size="sm" autocomplete="off"
                  disabled />
              </div>

              <div>
                <b-button @click="deleteTagGame(listItem.gameId, indexGame, listItem._id)" variant="outline-danger"
                  size="sm">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>


      </div>
      <div v-else>
        <div class="row">
          <div class="col">
            <div class="text-center">
              <h1>No hay juegos registrados</h1>
            </div>
          </div>
        </div>
      </div>



      <!-- actualizar  -->
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-button :disabled="loadingUpdateIndex" :variant="'success'" class="mr-2" size="sm" @click="updateIndexGames">
          <b-spinner v-if="loadingUpdateIndex" small />
          <feather-icon v-else :icon="'SaveIcon'" class="mr-50" />
          <span class="align-middle"> Actualizar </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BSpinner,
  BAvatar
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BAvatar,
    BForm,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    draggable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data: () => ({
    searchQuery: "",
    selectGames: [],
    openModalGames: false,
    isLoadingModalGames: false,
    loadingUpdateIndex: false,
    loading: false,
    search: null,
    tagId: null,
    openModalGamesDuplicate: false,
    selectGamesDuplicate: [],
    fields: [
      { label: "Name", key: "name", sortable: true },
      { label: "Status", key: "status", sortable: true },
      { label: "Games", key: "games", sortable: true },
      { label: "Created", key: "createdAt", sortable: true },
      { label: "Actions", key: "actions", sortable: true },
    ],
    items: [],
    tagName: "",
    formData: {
      name: "",
    },
    addTagModal: false,
    loadingDelete: false,
    isLoadingCreate: false,
    listDrag: [],
  }),
  computed: {
    itemsComputed() {
      // filter by searchQuery
      if (this.searchQuery) {
        return this.items.filter((item) => {
          return item.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
      return this.items;
    },
    selectGamesComputed() {
      // filter by searchQuery
      if (this.search) {
        return this.selectGames.filter((item) => {
          return item.gameName
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      }
      return this.selectGames;
    },
    
  },
  methods: {
    selectGamesMethoDuplicate(selectGames) {
      let gamesDuplicate = [];
    selectGames.forEach((item, index) => {
      const findIndex = gamesDuplicate.findIndex((itemDuplicate) => {
        return itemDuplicate.gameName === item.gameName;
      });

      // agregar solo los juegos duplicados
      if (findIndex === -1) {
        const games = selectGames.filter((itemFilter) => {
          return itemFilter.gameName === item.gameName;
        });

        if (games.length > 1) {
          gamesDuplicate.push({
            gameId: item.gameName,
            games,
            _id: item._id,
          });
        }
      }        
    });

    this.selectGamesDuplicate = gamesDuplicate;
    },
    isDuplicateGames(games) {
      // validar que sea un array y que tenga elementos repetidos
      if (Array.isArray(games) && games.length > 0) {
        const gamesDuplicate = games.filter((item, index) => {
          return games.indexOf(item) !== index;
        });

        return gamesDuplicate.length > 0;
      }
    },
    gamesChagePosition() {
      console.log(this.selectGames);
    },
    async updateIndexGames() {
      try {
        this.loadingUpdateIndex = true;

        this.selectGames.forEach((item, index) => {
          item.position = index + 1;
        });

        await this.$store.dispatch("tagsModule/updateIndexGames", {
          tagId: this.tagId,
          data: {
            games: this.selectGames,
          },
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.openModalGames = false;
        this.getTags();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUpdateIndex = false;
      }
    },
    async saveUpdateTag() {
      try {
        this.isLoadingCreate = true;

        if (this.formData._id) {
          await this.$store.dispatch("tagsModule/updateTag", this.formData);
        } else {
          await this.$store.dispatch("tagsModule/storeTag", this.formData);
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: this.formData._id
              ? "success update tag"
              : "success create tag",
          },
        });

        this.getTags();
        this.addTagModal = false;
        this.formData = {
          name: "",
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingCreate = false;
      }
    },
    async getTags(status = true) {
      try {
        this.loading = true;
        const response = await this.$store.dispatch("tagsModule/getTags", {
          status,
        });
        this.items = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    editTag(tag) {
      this.formData = tag;
      this.addTagModal = true;
    },
    deleteTagConfirm(tagId) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteTag(tagId);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async deleteTag(tagId) {
      try {
        this.loadingDelete = true;
        await this.$store.dispatch("tagsModule/deleteTag", tagId);
        this.getTags();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Tag deleted",
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDelete = false;
      }
    },
    deleteTagGame(gameId, index, idGame) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {

            const status = false;
            this.$store
              .dispatch("tagsModule/addTagGames", {
                tagId: this.tagId,
                data: {
                  status,
                  gameId,
                  idGame,
                },
              })
              .then(() => {

                const findIndex = this.selectGames.findIndex((item) => {
                  return item._id === idGame;
                });
                this.selectGames.splice(findIndex, 1);

                const findIndexTag = this.items.findIndex((item) => {
                  return item._id === this.tagId;
                });

                const findIndexGame = this.items[findIndexTag].games.findIndex((item) => {
                  return item._id === idGame;
                });

                this.items[findIndexTag].games.splice(findIndexGame, 1);

                this.selectGamesDuplicate.forEach((item, index) => {
                  const findIndexDuplicate = item.games.findIndex((item) => {
                    return item._id === idGame;
                  });

                  if (findIndexDuplicate !== -1) {
                    this.selectGamesDuplicate[index].games.splice(findIndexDuplicate, 1);
                  }
                });
                
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "CheckCircleIcon",
                    variant: "success",
                  },
                });

              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });


    },
    async gameInfo({ name, _id }) {
      try {
        this.isLoadingModalGames = true;
        this.openModalGames = true;

        this.tagName = name;

        const resGames = await this.$store.dispatch("tagsModule/getGamesByTag", _id);

        this.selectGames = resGames.map((item) => {
          item.positionByTag = item.positionByTag.filter((item, index, self) =>
            index === self.findIndex((t) => t.tagName === item.tagName) 
          );

          const findPosition = item.positionByTag.find((item) => {
            return item.tagName === name;
          });
          return {
            ...item,
            position: findPosition ? findPosition.position : 100,
            _id: item._id,
          };
        });

        this.selectGames.sort((a, b) => {
          return a.position - b.position;
        });

        this.selectGamesMethoDuplicate(this.selectGames);

        this.tagId = _id;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingModalGames = false;
      }
    },
  },
  mounted() {
    this.getTags();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
