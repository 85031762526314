import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios

// axiosIns.defaults.baseURL = 'https://whish-gateway-api.kingconnections.net/' 
// axiosIns.defaults.baseURL = 'http://127.0.0.1:3000/' 
axiosIns.defaults.baseURL = process.env.VUE_APP_GATEWAY
axiosIns.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
// process.env.VUE_APP_GATEWAY

Vue.prototype.$http = axiosIns


export default axiosIns