<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="Games upload by excel"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <b-button block variant="primary" :href="url_excel" target="_blank">
            <feather-icon icon="DownloadCloudIcon" />
            Download excel template
          </b-button>
        </b-col>

        <b-col md="12">
          <div>
            <label class="mt-2">File</label>
            <b-form-file
              accept=".xlsx"
              ref="file-input"
              v-model="file"
              class="mb-2"
              @change="handleFileUpload"
            />
          </div>
        </b-col>
        <b-col align-self="center" cols="12" class="d-flex justify-content-end">
          <b-button @click="modalShow = false">Cancel</b-button>
          <b-button variant="primary" @click="importGameExcel()" class="ml-1"
            >Upload
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

import {
  BButton,
  BModal,
  BCol,
  BRow,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BModal,
    BCol,
    BRow,
    BFormFile,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    modalShow: false,
    file: null,
    fileExcel: null,
    url_excel: `${process.env.VUE_APP_GATEWAY}download-archive-games`,
  }),
  methods: {
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    handleFileUpload(e) {
      this.fileExcel = e.target.files[0];
    },

    async importGameExcel() {
      if (this.file === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XCircleIcon",
            variant: "danger",
            text: "Please select a file",
          },
        });
        return;
      }
      const fd = new FormData();
      fd.append("file", this.fileExcel);
      const payload = {
        productId: this.productId,
        file: fd,
      };
      this.$emit("changeImportGameLoading", payload);
    },
  },
};
</script>

<style></style>
