<template>
  <div>
    <b-modal centered ref="modalShowConfig" hide-footer title="Client configuration">
      <div v-if="customerId" class="d-flex flex-column mb-2">
        <label>ID</label>
        <!-- button -->
        <b-button @click="onCopy(customerId, 'ID copied')" variant="outline-primary">
          {{ customerId }}
        </b-button>
      </div>

      <div v-if="token" class="d-flex flex-column mb-2">
        <label>Token</label>
        <!-- button -->
        <b-button @click="onCopy(token, 'Token copied')" variant="outline-primary">
          {{ token }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BFormInput, BFormGroup } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      customerId: "",
      token: "",
    };
  },
  methods: {
    showModal() {
      this.$refs["modalShowConfig"].show();
    },
    hideModal() {
      this.$refs["modalShowConfig"].hide();
    },
    showConfig() {
      this.showModal();
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    onCopy(text, message) {
      console.log(text, message);

      navigator.clipboard.writeText(text);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "CheckMarkIcon",
        },
      });
    },
  },
};
</script>
