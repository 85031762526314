
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        data: [],
    },
    mutations: {
        SET_DATA(state, payload){
            state.data =  payload.data
        }
    },
    actions: {
        // GET: /settings/default
        index({commit}) {
            return new Promise((resolve, reject) => {
                return axios.get(`settings/default`)
                    .then(response => {
                        commit('SET_DATA', response.data)
                        response.data?.productCreditLimits?.sort((a, b) => a.creditLimit - b.creditLimit);
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        // POST: /settings/default
        update({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/settings/default`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
