<template>
  <b-card>
    <!-- input filter -->
    <div class="mb-1">
      <b-row>
        <b-col v-if="typeUserLoged == 'Root'" md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1"
              >Cliente
            </label>
            <v-select

              v-model="filterSelect.customer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              :reduce="(customer) => customer._id"
              placeholder="Seleccione un cliente"
              value=""
            >
              <template #option="{ name }">
                <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- startData -->
        <b-col md="4">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr v-model="filterSelect.dateFrom" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y'
            }" />
          </b-form-group>
        </b-col>

        <!-- endData -->
        <b-col md="4">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr v-model="filterSelect.dateAt" class="form-control" :config="{
              altInput: true,
              altFormat: 'd-m-Y'
            }" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group :label="$t('labels.search')">
            <b-form-input v-model="filterSelect.q" class="d-inline-block mr-1" :placeholder="$t('labels.search')" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button :disabled="loadingData" @click="inputSelect()" class="text-nowrap" variant="primary">
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{ $t('labels.search') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- alert: Despues de 5 minutos no se puede anular el ticket -->
    <div class="d-flex" v-if="rows.length > 0">
      <b-alert 
    class="d-flex"
    variant="danger" show>
      <div class="alert-body">
        <span>Despues de 5min no se puede anular el ticket</span>
      </div>
    </b-alert>
    </div>
    <!-- table -->
    <b-overlay :show="loadingData" variant="transparent">
      <vue-good-table mode="remote" :columns="columns" :rows="rows" :totalRows="totalRows"
        :pagination-options="{ enabled: true }" :sort-options="{ enabled: false }">
        <template slot="table-row" slot-scope="props">


          <div v-if="props.column.field == 'roundId'">
            <div>{{ props.row.request | showRoundId }}</div>
          </div>


          <div v-else-if="props.column.field == 'createdAt'">
            <div>{{ props.row.createdAt | formatDate }}</div>
          </div>

          <!-- amount -->
          <div v-else-if="props.column.field == 'amount'">
            <div>{{ formatAmount(props.row) }}</div>
          </div>

          <div v-else-if="props.column.field == 'isAnnulled'">
               <!-- showButtonAnulled -->
                <b-button v-if="!showButtonAnulled(props.row)" @click="onAnnulledChange(props.row)" variant="outline-primary" size="sm">
                    Anular
                </b-button>

                <!-- if isAnnulled -->
                <div v-if="props.row.isAnnulled">
                  <b-badge variant="danger">Anulado</b-badge>
                </div>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select v-model="serverParams.perPage" :options="pages" class="mx-1" @input="onPerPageChange" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.perPage" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="onPageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BBadge,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDateToMonthShort, formatDate } from '@core/utils/filter'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    Prism,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BBadge,
    BAlert,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  // add filters
  filters: {
    formatDate,
    pretty: function (value) {
      return value;
    },
    showRoundId: function (request) {
      const possileVal =
        [
          "playId",
          "referenceId",
          "transactionId",
          "transaction_id",
          "id_bet",
          "round_id",
          "bet_code",
          "roundId",
          "reference"
        ]
      let roundId = null;
      possileVal.forEach((val) => {
        if (request[val]) {
          roundId = request[val]
        }
      })
      return roundId;
    },
  },
  data() {
    return {
      showFilter: false,
      loadingData: false,
      pages: [20, 40, 60],
      columns: [
        { label: "Nro Ticket", field: "nroTicket" },
        { label: "Usuario", field: "username" },
        { label: "Cliente", field: "customer" },
        { label: "Monto Apostado", field: "amount" },
        { label: "Fecha", field: "createdAt" },
        { label: "Anulado", field: "isAnnulled" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        perPage: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      filterSelect: {
        customer: "",
        dateFrom: new Date(),
        dateAt: new Date(),
        q: "",
      },
      timeout: null,
    };
  },
  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
  },
  methods: {
    async getTicketsSportsbook() {
      try {
        this.loadingData = true;
        this.filterSelect.dateFrom = moment(this.filterSelect.dateFrom).format('YYYY-MM-DD');
        this.filterSelect.dateAt = moment(this.filterSelect.dateAt).format('YYYY-MM-DD');

        //new  form data
        const formData = new FormData();
        formData.append("dateFrom", this.filterSelect.dateFrom);
        formData.append("dateAt", this.filterSelect.dateAt);
        formData.append("paginate[page]", this.serverParams.page);
        formData.append("paginate[perPage]", this.serverParams.perPage);
        formData.append("search", this.filterSelect.q);
        formData.append("customerId", this.filterSelect.customer);

        const res = await this.$store.dispatch("reports/ticketsSportsbook", formData);
        this.rows = res.data.data;
        this.totalRows = res.data.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tickets",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    onAnnulledChange(row) {
      this.$swal({
        title: "Por favor confirme",
        text: `Esta seguro de anular el ticket ${row.nroTicket}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, anular!",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await this.$store.dispatch("reports/cancelTicket", {
              id: row._id,
              isAnnulled: row.isAnnulled,
              tokenClient: row.tokenClient,
              ticket: row.nroTicket,
            });

            if(res.data.cancel){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }

            this.getTicketsSportsbook();
          } catch (error) {
            row.isAnnulled = !row.isAnnulled;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error updating ticket",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }
      });
    },
    fillZero(number, length) {
      var my_string = '' + number;
      while (my_string.length < length) {
        my_string = '0' + my_string;
      }

      return my_string;
    },
    formatAmount({amount, currency}) {
      // se debe retornar asi USD 1.000,00
      const amountFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount);
      return amountFormat;
    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getTicketsSportsbook();
    },
    onPerPageChange(perPage) {
      this.serverParams.page = 1
      this.serverParams.perPage = perPage;
      this.getTicketsSportsbook();
    },
    inputSelect() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.serverParams.page = 1
        this.getTicketsSportsbook();
      }, 500);

    },
    async getCustomer() {
      const res = await this.$store.dispatch("reports/getCustomer");
      this.customerOptions = res.data.customers;
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    showButtonAnulled(row) {
      // validar con moment si createdAt es mayor a 5 minutos(return true)
      const createdAt = moment(row.createdAt);
      const now = moment();
      const diff = now.diff(createdAt, 'minutes');
      if (diff > 5 && !row.isAnnulled) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports")){
    store.registerModule("reports", reportsStoreModule);
    }
  this.getCustomer();
},
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

