import moment from 'moment';
import store from '@/store';

export const tokenCheck = async () => {
    try {
        if(!localStorage.getItem('userData') && !localStorage.getItem('accessToken')) return false

        const response = await store.dispatch('usersModule/tokenCheck')
        const userInfo = response.data.data
        const setUserData = {
            userData: userInfo,
            accessToken: userInfo.token,
            timeRefreshToken: userInfo.expiresIn,
            minRefreshToken: userInfo.expiresIn
        }
        setAuthLocalStorage(setUserData)
    } catch (error) {
        console.log(error)
        // en caso de que regrese error 401 o 403 se elimina el local storage y se redirecciona al login
        if(error.response.status === 401 || error.response.status === 403){
            deleteAuthLocalStorage()
            window.location.href = '/login'
        }
    }
}

export const setAuthLocalStorage = ({ userData, accessToken, timeRefreshToken, minRefreshToken }) => {
    // set local storage
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('userData', JSON.stringify(userData))
    localStorage.setItem('minRefreshToken', minRefreshToken)

    // set refresh token
    const dateRefreshToken = moment().add(timeRefreshToken, 'minutes').toDate()
    localStorage.setItem('timeRefreshToken', dateRefreshToken)
}

export const deleteAuthLocalStorage = () => {
    // remove local storage
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('timeRefreshToken'),
    localStorage.removeItem('minRefreshToken')
}

export const requestRefreshToken = () => {
    if(!localStorage.getItem('userData') && !localStorage.getItem('accessToken')) return false

    // cuantos milisegundos faltan para que expire el token
    const dateTimeRefreshToken = new Date(localStorage.getItem('timeRefreshToken'))
    const timeRefreshToken = moment(dateTimeRefreshToken).diff(moment(), 'miliseconds')
    setTimeout(() => {
        tokenCheck()
        requestRefreshToken()
    }, timeRefreshToken - 30000)
}

// cerrar sesion por inactividad
export const logoutSessionIdle = (document) => {
    if(!localStorage.getItem('userData') && !localStorage.getItem('accessToken')) return false
    requestRefreshToken();

    let setTimeOut = null    
    // cerrar sesision function 
    const logoutSession = () => {
        clearTimeout(setTimeOut)    
        const timeRefreshToken =  localStorage.getItem('minRefreshToken') * 60000
        setTimeOut = setTimeout(() => {
            deleteAuthLocalStorage()
            window.location.reload()
        }, timeRefreshToken)
    }

    // detectar inactividad del maouse
    document.addEventListener('mousemove', () => {
        logoutSession()
    })        
}

