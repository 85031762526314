<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- table -->

    <!-- boton de refresh -->
    <b-row class="mb-2">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="primary" @click="getLogScan" size="sm">
          <feather-icon icon="RefreshCwIcon" class="mr-1" />
          <span>Actualizar</span>
        </b-button>
      </b-col>
    </b-row>
    <vue-good-table
      :columns="columns"
      :rows="listLogsScan"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: '',
      }"
      styleClass="vgt-table"
      compactMode
    >
      <!-- format date createAt  -->
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'lastDateScan'">
          <p>{{ props.row.lastDateScan | formatDate }}</p>
        </div>

        <div
          v-else-if="props.column.field == 'isRunning'"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <b-badge variant="success" v-if="props.row.isRunning">Si</b-badge>
          <b-badge variant="danger" v-else>No</b-badge>

          <!-- barra de procentaje usando: props.row.progress -->
          <div class="w-100 my-1 text-center">
            <b-progress v-if="props.row.isRunning" :value="props.row.progress" :max="100">
              <b-progress-bar
                :value="props.row.progress"
                :label="`${props.row.progress.toFixed(2)}%`"
                :variant="props.row.progress == 100 ? 'success' : 'primary'"
              />
            </b-progress>

            {{ props.row.currentProduct }}
          </div>
        </div>

        <!-- isFinished -->
        <div v-else-if="props.column.field == 'isFinished'">
          <b-badge variant="success" v-if="props.row.isFinished">Si</b-badge>
          <b-badge variant="danger" v-else>No</b-badge>
        </div>

        <!-- actions -->
        <div v-else-if="props.column.field == 'actions'">
          <b-overlay
            :show="loadingDataSync"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
          >
            <b-button
              size="sm"
              :variant="props.row.isRunning ? 'danger' : 'success'"
              @click="syncReport(props.row.reportName)"
              >
              {{ props.row.isRunning ? 'Stop' : 'Start' }}
              </b-button
            >
          </b-overlay>
        </div>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </b-card>
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BInputGroupPrepend,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BProgress,
    BProgressBar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    loadingData: false,
    loadingDataSync: false,
    // reportName
    // lastDateScan
    // progress
    // currentProduct
    // isRunning
    // isFinished

    columns: [
      { label: "Reporte", field: "reportName" },
      { label: "Ultima sincronización", field: "lastDateScan" },
      { label: "Ejecutandose", field: "isRunning" },
      { label: "Sync Finalizado", field: "isFinished" },
      { label: "Time Seg Loading", field: "timeSegLoading" },
      { label: "Total Items Grupos", field: "totalItemGroup" },
      { label: "Total Item Old", field: "totalItemOld" },
      { label: "Log Last", field: "logLastError" },
      { label: "Acciones", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    listLogsScan: [],
  }),
  computed: {},
  methods: {
    async getLogScan() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("synchronizeReportsModule/getLogScan");
        this.listLogsScan = res.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async syncReport(reportName) {
      try {
        this.loadingDataSync = true;
        const res = await this.$store.dispatch("synchronizeReportsModule/syncReport", {
          reportName,
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sincronizando reporte",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        this.getLogScan();
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error sincronizando reporte",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataSync = false;
      }
    },
  },
  mounted() {
    this.getLogScan();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.form-group {
  margin-bottom: 0 !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
