<template>
  <div v-if="openModal" class="mt-2">
    <b-modal
      v-model="openModal"
      title="Open Game"
      hide-footer
      modal-class="modal-primary"
      centered
      no-close-on-backdrop
      @hidden="resetModalData"
    >
      <b-overlay :show="isLoading" variant="transparent" no-wrap />
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label class="mr-1 text-lg">Clientes</label>
            <v-select
              v-model="selectCustomer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions"
              label="name"
              placeholder="Cliente"
              value=""
              @input="dataPlayer((isDemo = true))"
              :loading="isloadingDataCustomer"
            >
              <template #option="{ name }">
                <feather-icon
                  icon="UserIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label class="mr-1 text-lg">Usuario Demo</label>
            <v-select
              v-model="selectPlayers"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="playersOptions"
              label="name"
              value=""
              placeholder="Selecione un usuario"
              :disabled="!selectCustomer"
              @search="
                (search) => {
                  this.search = search;
                  this.dataPlayer();
                }
              "
              :loading="isloadingDataPlayer"
            >
              <template #selected-option="{ name, currency }">
                <div>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="align-middle"
                  />
                  <span> {{ name }}</span>
                </div>
                <div>
                  <feather-icon
                    icon="ApertureIcon"
                    size="16"
                    class="align-middle ml-1"
                  />
                  <span> {{ currency }}</span>
                </div>
              </template>
              <template #option="{ name, currency }">
                <div>
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ name }}</span>
                </div>
                <div>
                  <feather-icon
                    icon="ApertureIcon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ currency }}</span>
                </div>
              </template>
            </v-select>
            <span v-if="isUserNotFound" class="text-xs text-danger"
              >No se encontro ningun usuario demo, Busca un usuario para
              selecionar</span
            >
          </b-form-group>
        </b-col>
        <b-col cols="12" class="mb-1">
          <b-button
            :disabled="!selectCustomer && !selectPlayers"
            variant="primary"
            class="w-100"
            @click="launch"
            >Launch</b-button
          >
        </b-col>

        <b-col v-if="resLaunch.data" cols="12">
          <label class="mr-1 text-lg">Response Launch</label>
          <prism language="js" class="rounded">
            {{ resLaunch.data }}
          </prism>
        </b-col>
        <b-col align-self="center" cols="12" class="d-flex justify-content-end">
          <b-button @click="openModal = false" class="mr-1">Close</b-button>
          <div v-if="resLaunch.data">
            <b-button
              v-if="resLaunch.data.launch"
              variant="primary"
              :href="resLaunch.data.launch"
              target="_blank"
              >Open Game</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormFile,
  BImg,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "UploadImegesGames",
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BOverlay,
    BFormFile,
    BImg,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    ToastificationContent,
    vSelect,
    Prism,
  },
  data() {
    return {
      openModal: false,
      isLoading: false,
      isloadingDataPlayer: false,
      isloadingDataCustomer: false,
      isUserNotFound: false,
      selectCustomer: "",
      selectPlayers: "",
      search: "",
      inidvidualCred: null,
      resGame: null,
      resLaunch: {},
      customerOptions: [],
      playersOptions: [],
    };
  },
  methods: {
    async dataCustomer() {
      try {
        this.isloadingDataCustomer = true;
        const res = await this.$store.dispatch("customerModule/getCustomers", {
          paginate: {
            page: 1,
            perPage: 1000,
            sortDesc: true,
          },
        });
        this.customerOptions = res?.data?.customers;
        this.isloadingDataCustomer = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isloadingDataCustomer = false;
      }
    },
    async dataPlayer(isDemo = false) {
      try {
        this.isUserNotFound = false;
        this.isloadingDataPlayer = true;
        let params = {
          paginate: {
            page: 1,
            perPage: 1000,
            sortDesc: true,
          },
        };
        let res;
        if (this.search) {
          params.search = this.search;
        } else {
          params.isSetDemo = true;
        }
        if (isDemo) {
          params.isSetDemo = true;
        }
        if (this.selectCustomer) {
          params.customerId = this.selectCustomer.customerId;
          res = await this.$store.dispatch(
            "usersModule/getUsersPlayers",
            params
          );
          if (res?.users?.length == 0) {
            this.isUserNotFound = true;
          }
          if (isDemo) {
            this.selectPlayers = res?.users[0];
          }
        }
        this.playersOptions = res?.users;
        this.isloadingDataPlayer = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isloadingDataPlayer = false;
      }
    },
    async launch() {
      try {
        this.isLoading = true;
        if (!this.selectCustomer) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error open game",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Debes selecionar un cliente`,
            },
          });
          return;
        }
        if (!this.selectPlayers) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error open game",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `Debes selecionar un usuario demo`,
            },
          });
          return;
        }
        console.log("individual cred", this.individualCred)
        console.log("RESGAME", this.resGame)
        let payload = {
          productId: this.resGame?.product?.productId,
          individualCred: this.individualCred || null,
          gameId: this.resGame?.gameId,
          tokenClient: this.selectCustomer?.token,
          userId: this.selectPlayers?.userId,
          currency: this.selectPlayers?.currency,
          language: this.selectPlayers?.language || "es",
          country: this.selectPlayers?.country || "ES",
          lobbyURL: "https://admin-staging.integrationscenter.net",
        };
        console.log("payload", payload)
        const res = await this.$store.dispatch(
          "moduleProducts/launchGame",
          payload
        );
        this.resLaunch = res;
        this.customerOptions = res?.data?.customers;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.resLaunch = error?.response;
      } finally {
        this.isLoading = false;
      }
    },
    resetModalData() {
      this.selectCustomer = "";
      this.selectPlayers = "";
      this.resLaunch = {};
      this.isLoading = false;
      this.isDemo = false;
      this.isResLaunch = false;
    },
  },
  mounted() {
    this.dataCustomer();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 150px;
}
</style>
