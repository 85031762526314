<template>
  <div>
    <b-card>
  <!-- FILTER START -->
      <b-row>
        <b-col md="4">
          <b-form-group label="Product">
            <v-select
              v-model="filterSelect.selectProduct"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions"
              label="name"
              @input="inputSelect"
              :reduce="(product) => product.productId"
              placeholder="Select product"
              value=""
            />
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-form-group label="Date" class="flatpickr">
            <b-input-group>
              <flat-pickr
                v-model="filterSelect.dateSelect"
                class="form-control"
                @on-change="inputSelect"
                :config="{ wrap: true,  mode: 'range' }"
                placeholder="Select date"
              />

              <b-input-group-append v-show="filterSelect.dateSelect">
                <b-button variant="outline-danger" @click="filterSelect.dateSelect = ''"> X </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-modal
        id="modalResponse"
        title="PopUp Response"
        cancel-variant="outline-secondary"
        scrollable
      >
        <b-card-text>
          {{ rows }}
        </b-card-text>
      </b-modal>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="tableData"
        :totalRows="tableData.length"
        :pagination-options="{ enabled: true }"
        :sort-options="{ enabled: false }"
      >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'createdAt'">
          <div>{{ props.row.createdAt | formatDate}}</div>
        </div>
        <div v-else-if="props.column.field == 'type'">
          <!-- debit or credit span danger success -->
            <span :class="props.row.type == 'debit' ? 'badge-danger' : 'badge-success'" class="badge">{{ props.row.type == 'debit' ? 'Debit' : 'Credit' }}</span>
        </div>
        <div v-else-if="props.column.field == 'amount'">
          <div>{{ props.row.amount | currency({symbol: ''}) }}</div>
        </div>
      </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="serverParams.limit"
                :options="pages"
                class="mx-1"
                @input="onPerPageChange"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="serverParams.limit"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="onPageChange"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from '@core/utils/filter'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      showFilter: false,
      loadingData: false,
      pages: [20, 40, 60],
      columns: [
        { label: "Game", field: "game" },
        { label: "Plataform", field: "plataform" },
        { label: "Number Of Spins", field: "numberSpins", sortable: true },
      ],
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      currencyssOptions:[],
      filterSelect: {
        dateSelect: "",
        selectCustomer: "",
        selectProduct: "",
        selectCurrency: ""
      },
    };
  },
  computed:{
    cardCabezera(){
      return [
        {
          statistic: 1.866,
          color: 'success',
          icon: "PlayCircleIcon",
          title: "Total Spins"
        },
        {
          statistic: '48.323,50 AR$',
          color: 'danger',
          icon: "ActivityIcon",
          title: "Total Sales"
        },
        {
          statistic: '41.935,50 AR$',
          color: 'success',
          icon: "TrophyIcon",
          title: "Total Wins"
          
        },
        {
          statistic: '6.388,00 AR$',
          color: 'danger',
          icon: "DollarSignIcon",
          title: "Total Profit"
        }
      ]
    },
    tableData(){
      return [
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 2.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 2.766
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 20
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        {
          game: 'Live - Mega Roulette',
          plataform: "Pragmaticg",
          numberSpins: 1.866
        },
        

        
      ]
    }
  },
  watch: {
    showFilter(val){
      if(val) {
        this.getCustomer();
        this.getProducts();
        // this.getCurrencys();
      }
    }
  },
  // add filters
  filters: {
    formatDate
  },

  methods: {
    async getTransactions() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "reports/getTransactions",
          {...this.filterSelect, ...this.serverParams}
        );
        this.rows = res.data.data;
        this.totalRows = res.data.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching transactions list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    onPageChange(page) {
      console.log(page, "page")
      this.serverParams.page = page;
      this.getTransactions();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1
      this.serverParams.limit = limit;
      this.getTransactions();
    },
    inputSelect() {
      this.getTransactions();
    },
    async getCustomer() {
      const res = await this.$store.dispatch("reports/getCustomer");
      this.customerOptions = res.data;
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    async getCurrencys(){
      const res = await this.$store.dispatch("reports/getCurrencys");
      this.currencyssOptions = res.data.currencys;
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getTransactions();
    this.getProducts();
    this.getCurrencys();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

