<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      :title="`Block product to user ${user.username}`"
      hide-footer
    >
      <b-card
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 300px; overflow-y: scroll"
        no-body
        v-if="modalShow"
      >
       <b-form-input
          v-if="showSeachProduct"
          v-model="searchQueryProduct"
          class="d-inline-block mb-1"
          placeholder="Search Products..."
          size="sm"
        />
        <b-list-group>
          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Game</span>

            <div>Status</div>
          </b-list-group-item>
           <div class="m-2 text-center" v-if="!productsOptions.length >= 1">
                <b-overlay show variant="transparent" no-wrap />
            </div>

          <b-list-group-item
           v-else
            v-for="(tr, index) in productsOptions"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
          <b-overlay :show="loading" variant="transparent" no-wrap />
            <div>
              <span>{{tr.name}}  </span>
              <b-badge
              v-if="tr.isgame"
              pill
              :variant="`light-secondary`"
              class="text-capitalize"
            > Product game
            </b-badge>
            </div>

            <b-form-checkbox
              :checked="user.restrictedProducts.includes(tr._id)"
              class="custom-control-danger"
              name="check-button"
              switch
              @change="blockProduct(tr._id)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="LockIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="UnlockIcon" />
              </span>
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group> 
        

      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BCard,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BModal,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BCard,
    BOverlay,
    BBadge
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    modalShow: false,
    user: {},
    showSeachProduct: false,
    searchQueryProduct: "",
    loading: false,
  }),
  watch:{
      modalShow(val){
          if(val){
              this.$store.dispatch('moduleProducts/getProducts')
          }
      }
  },
  computed: {
    productsOptions(){
      return  this.$store.getters['moduleProducts/productsActives'].filter((product) => {
        return product.name.toLowerCase().includes(this.searchQueryProduct.toLowerCase())
      })
    }
  },
  methods: {
    async blockProduct(_id) {
      const obj = {
        userId: this.user.userId,
        product: _id,
        status: this.user.restrictedProducts.includes(_id)
      };
      // const indexToken = this.user.tokens.findIndex(p => p.gameId == gameId)
      try{
          this.loading = true
        //  this.$set(this.user.tokens, indexToken , tr)
         await this.$store.dispatch("usersModule/blockProduct", obj);
         if(!obj.status) this.user.restrictedProducts.push(_id)
         else{
            const index = this.user.restrictedProducts.findIndex(p => p == _id)
            this.user.restrictedProducts.splice(index, 1)
         }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${obj.status ? 'Game successfully unlocked' : 'Game blocked successfully'}`,
            icon: `${obj.status ? 'UnlockIcon' : 'LockIcon'}`,
            variant: `${obj.status ? 'success' : 'success'}`,
          },
        });
        this.$store.dispatch("usersModule/getUsers");
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `An error occurred, please contact the administrator`,
          },
        });
      }finally{
        this.loading = false
      }
    },
  },
};
</script>

<style>
</style>