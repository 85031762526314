<template>
  <div v-if="openModal" class="mt-2">
    <b-modal
      v-model="openModal"
      title="Documentation"
      hide-footer
      modal-class="modal-primary"
      centered
      no-close-on-backdrop
      size="lg"
      @hidden="resetModalData"
    >
      <b-overlay :show="isLoading" variant="transparent" no-wrap />
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-overlay :show="loadingUploadFile" opacity="0.6">
            <template v-slot:overlay>
              <div class="d-flex align-items-center">
                <b-spinner small type="grow" variant="secondary" />
                <b-spinner type="grow" variant="dark" />
                <b-spinner small type="grow" variant="secondary" />
                <!-- We add an SR only text for screen readers -->
                <span class="sr-only">Please wait...</span>
              </div>
            </template>

            <b-form-group label="File">
              <b-input-group class="d-flex" invalid-feedback="file is required">
                <b-input-group-prepend>
                  <b-button
                    :loading="!loadingUploadFile"
                    variant="primary"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    <feather-icon icon="UploadIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input v-model="product.url_document" required />
                <b-button
                  v-if="product.url_document"
                  class="ml-1"
                  variant="primary"
                  :href="product.url_document"
                  target="_blank"
                  >Open</b-button
                >
              </b-input-group>
            </b-form-group>
          </b-overlay>

          <b-form-file
            ref="refInputEl"
            v-model="file"
            accept=".pdf, .txt"
            :hidden="true"
            plain
            @input="uploadFile"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Credential Documentacion"
            label-for="documentationCredentials"
          >
            <v-jsoneditor
              v-model="product.documentationCredentials"
              :plus="true"
              height="350px"
              :options="configOptionsEditJson"
            />
          </b-form-group>
        </b-col>
        <b-col align-self="center" cols="12" class="d-flex justify-content-end">
          <b-button @click="openModal = false" class="mr-1">Close</b-button>
          <b-button variant="primary" @click="updateProduct">Update</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormFile,
  BImg,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BInputGroupPrepend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import VJsoneditor from "v-jsoneditor/src/index";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "UploadImegesGames",
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BOverlay,
    BFormFile,
    BImg,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BInputGroupPrepend,
    BInputGroup,
    BSpinner,
    ToastificationContent,
    vSelect,
    Prism,
    VJsoneditor,
  },
  data() {
    return {
      configOptionsEditJson: {
        mode: "code",
        modes: ["code", "tree"],
        isExpand: true,
        height: "auto",
        navigationBar: true,
      },
      openModal: false,
      isLoading: false,
      loadingUploadFile: false,
      product: null,
      file: null,
    };
  },
  methods: {
    async uploadFile(fileData) {
      if (!fileData) {
        console.error("No se ha seleccionado ningún archivo.");
        return;
      }
      try {
        this.loadingUploadFile = true;
        const response = await this.$store.dispatch("filesModule/uploadFile", {
          fileData,
          path: "IntregationDocumentation",
        });
        this.product.url_document = response?.data?.url;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "File uploaded",
          },
        });
        this.file = null;
      } catch (error) {
        console.error("Error subiendo la File:", error);
      } finally {
        this.loadingUploadFile = false;
      }
    },

    async updateProduct() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          "productsModule/updateProduct",
          this.product
        );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Documentation Loaded Correctly",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.openModal = false;
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error uploading documentation",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.isLoading = false;
      }
    },
    resetModalData() {
      this.isLoading = false;
      this.loadingUploadFile = false;
      this.product = null;
      this.file = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 150px;
}
</style>
