
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        games: [],
    },
    getters: {},
    mutations: {
        GET_GAMES(state, payload){
            state.games =  payload
        },
    },
    actions: {
        getGames({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.get(`product/${payload.producSelectId}/games`, {params: payload.params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addGame({commit}, {gameData, product}) {
            return new Promise((resolve, reject) => {
                return axios.post(`/products/${product}/games`, gameData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        deleteGame({commit}, {productId, gameId }) {
            return new Promise((resolve, reject) => {
                return axios.delete(`/products/${productId}/games/${gameId}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateGame({commit}, {gameData, gameDataOld}) {
            return new Promise((resolve, reject) => {
                return axios.put(`/products/${gameDataOld.product}/games/${gameDataOld.gameID}`, gameData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        fetchTagsOptions({commit}, search = '') {
            return new Promise((resolve, reject) => {
                return axios.get(`games/listtags/${search}`)
                    .then(response => {
                        resolve(response.data.tags)
                    })
                    .catch(error => reject(error))
            })
        },
        desactiveActiveGames({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/products/games/status`, payload).then(response => {
                    resolve(response)
                }).catch(error => reject(error))
            })
        },
        changeStatus({commit}, {gameData, gameDataOld}) {
            return new Promise((resolve, reject) => {
                return axios.put(`/products/${gameDataOld.product}/games/${gameDataOld.gameID}`, gameData)
            })
        },
        desactiveActiveGames({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/products/games/status`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        getFieldsImages({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.get(`/key-image-collection-game`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        storeFieldsImages({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/key-image-collection-game`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        deleteFieldsImages({commit}, id) {
            return new Promise((resolve, reject) => {
                return axios.delete(`/key-image-collection-game/${id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
