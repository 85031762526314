<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col v-if="typeUserLoged == 'Root'" md="5" inline>
        <b-form-group>
          <label class="mr-1 text-lg" for="customRadioInline1">Clientes </label>
          <v-select
            class="mb-1"
            v-model="filterSelect.selectCustomer"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="customerOptions"
            label="name"
            :reduce="(customer) => customer.customerId"
            placeholder="Cliente"
            value=""
            @input="searchDileayed"
          >
            <template #option="{ name }">
              <feather-icon
                icon="UserIcon"
                size="16"
                class="align-middle mr-50"
              />
              <span> {{ name }}</span>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group>
          <label class="mr-1">Monedas</label>
          <v-select
            class="mb-1"
            v-model="filterSelect.selectCurrency"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="currencyssOptions"
            placeholder="Moneda"
            value="Bs"
            @input="searchDileayed"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="5">
        <label class="mr-1">Buscar</label>
        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" />
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="usersList"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: '',
      }"
      styleClass="vgt-table"
      compactMode
    >
      <!-- format date createAt  -->
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'createdAt'">
          <p>{{ props.row.createdAt | formatDate }}</p>
        </div>

        <div v-else-if="props.column.field == 'ids'">
          <!-- mini table: _id, userId, user_id -->
          <div class="d-flex text-nowrap align-items-center">
            <div class="d-flex flex-column mr-1">
              <p class="mb-0 font-weight-bold">_id:</p>
              <p class="mb-0 font-weight-bold">userId:</p>
              <p class="mb-0 font-weight-bold">user_id:</p>
            </div>
            <div class="d-flex flex-column mr-1">
              <p class="mb-0">{{ props.row._id }}</p>
              <p class="mb-0">{{ props.row.userId }}</p>
              <p class="mb-0">{{ props.row.user_id }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="props.column.field == 'isDemo'">
          <b-form-group>
            <b-form-checkbox
              v-model="props.row.isDemo"
              :id="'isDemo' + props.row._id"
              class="custom-control-success"
              switch
              inline
              @change="setIsDemo(props.row)"
            />
          </b-form-group>
        </div>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <section style="padding: 30px 0 0px 0px">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="current_page"
            :total-rows="total"
            :per-page="per_page"
            first-number
            @change="getUsers"
            align="right"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    customerOptions: [],
    showPassword: false,
    required,
    openModal: false,
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    formData: {
      name: "",
      lastname: "",
      customerId: "",
      username: "",
      password: "",
      typeUser: "",
    },
    filterSelect: {
      dateTo: null,
      dateFrom: null,
      selectCustomer: "",
      selectProduct: "",
      selectCurrency: "",
      q: "",
    },
    columns: [
      { label: "Nombre", field: "name" },
      { label: "usuario", field: "username" },
      { label: "Email", field: "email" },
      { label: "Moneda", field: "currency" },
      { label: "CustomerId", field: "customerId" },
      { label: "isd", field: "ids" },
      { label: "Demo", field: "isDemo" },
      { label: "createdAt", field: "createdAt" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    timeOutSearchCustomer: null,
    usersList: [],
    currencyssOptions: [
      "ARS",
      "BRL",
      "CLP",
      "COP",
      "EUR",
      "MXN",
      "MYR",
      "PEN",
      "PYG",
      "USD",
      "UYU",
      "VES",
      "XAF",
    ],
    timeoutSearch: null,
  }),
  watch: {
    searchQuery() {
      this.searchDileayed();
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    typeUserOptions() {
      if (this.typeUserLoged == "Root") {
        return [
          { text: "Super Admin", value: "superadmin" },
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      } else {
        return [
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      }
    },
  },
  methods: {
    searchDileayed() {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.getUsers();
      }, 500);
    },

    async dataCustomer() {
      try {
        const res = await this.$store.dispatch("customerModule/getCustomers", {
          paginate: {
            page: 1,
            perPage: 1000,
            sortDesc: true,
          },
        });
        this.customerOptions = res.data.customers;
      } catch (error) {
        console.log(error);
      }
    },

    async getUsers(page = 1) {
      try {
        let params = {
          paginate: {
            page: page,
            perPage: this.per_page,
            sortDesc: true,
          },
        };
        if (this.searchQuery) {
          params.search = this.searchQuery;
        }
        if (this.filterSelect.selectCurrency) {
          params.currency = this.filterSelect.selectCurrency;
        }
        if (this.filterSelect.selectCustomer) {
          params.customerId = this.filterSelect.selectCustomer;
        }
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "usersModule/getUsersPlayers",
          params
        );

        this.usersList = res.users;
        this.total = res.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    async setIsDemo(userClient) {  
      let payload = {
        _id: userClient._id,
        customerId: userClient.customerId,
        isDemo: userClient.isDemo,
      };
      console.log(payload);
      
      await this.$store.dispatch("customerModule/setIsDemo", payload);
      this.getUsers(this.current_page)
    },
    showGames(user) {
      this.$refs.games.user = JSON.parse(JSON.stringify(user));
      this.$refs.games.modalShow = true;
    },
    showProduct(user) {
      this.$refs.products.user = JSON.parse(JSON.stringify(user));
      this.$refs.products.modalShow = true;
    },
    userChangePassword(user) {
      this.$refs.userChangePassword.user = JSON.parse(JSON.stringify(user));
      this.$refs.userChangePassword.modalShow = true;
    },
  },
  mounted() {
    this.getUsers();
    this.dataCustomer();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.form-group {
  margin-bottom: 0 !important;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
