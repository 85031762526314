<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- Header -->
    <div
      class="
        d-flex
        justify-content-between
        align-items-center
        content-sidebar-header
        px-2
        py-1
      "
    >
      <h5 class="mb-0">{{modeEdit ? $t('Edit') : $t('new_category')}} {{category.name}}</h5>
    </div>
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent=" handleSubmit(modeEdit ? updateCategory : saveForm)"
        @reset.prevent="resetForm"
      >
      <validation-provider
          #default="validationContext"
          name="category_id"
          rules=""
        >
          <b-form-group :label="$t('category_id')" label-for="name">
            <b-form-input
              id="name"
              v-model="category.categoryId"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="category_name"
          rules="required"
        >
          <b-form-group :label="$t('category_name')" label-for="name">
            <b-form-input
              id="name"
              v-model="category.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="description"
          rules="required"
        >
          <b-form-group :label="$t('labels.description')" label-for="name">
            <b-form-input
              id="name"
              v-model="category.description"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>

        <b-form-group :label="$t('labels.status')" label-for="status">
          <b-form-checkbox v-model="category.status" id="status" class="custom-control-success" switch inline>
            <b>{{ category.status ? "Active" : "Unactive" }}</b>
          </b-form-checkbox>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex align-items-center justify-content-between mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="modeEdit ? 'success' : 'primary'"
            class="mr-2"
            type="submit"
          >
            <feather-icon :icon="modeEdit ? 'RefreshCcwIcon' : 'PlusIcon'" class="mr-50" />
            <span class="align-middle">{{modeEdit ? 'Update' : 'Add'}}</span>
          </b-button>
          <b-button
            @click="$emit('hiddentSidebar')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">{{$t('buttons.cancel')}}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  
  data() {
    return {
      categoriesOptions: [],
      required,
      modeEdit: false,
      loadingData: false,
      category: {
        name: "",
        description: "",
        status: true,
      },
    };
  },
  watch:{
      modeEdit(val){
          if(!val) this.resetForm();
      }
  },
  methods: {
    // Save category
    async saveForm() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("gameCategoryModule/store", this.category);
        this.resetForm();
        this.$emit('getCategory')  
        this.$emit("hiddentSidebar");        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("message.category_add"),
            icon: "CheckCircleIcon",
            variant: "success"
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("message.category_add_error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    // Save category
    async updateCategory() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("gameCategoryModule/update", this.category);
        this.modeEdit = false 
        this.$emit('hiddentSidebar'); 
        this.$emit('getCategory')       
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("message.category_update"),
            icon: "CheckCircleIcon",
            variant: "success"
          },
        });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("message.category_update_error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response?.data?.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    //---Validate State Fields
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {
      this.category = {
        name: "",
        description: "",
        status: true,
      };
      this.$refs.refFormObserver.reset();
    },
    edit({_id, categoryId, name, description, status }){
      this.category = {
        _id,
        categoryId,
        name,
        description,
        status
      },
      this.modeEdit = true
    }
  }, 
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
