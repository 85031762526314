import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleProducts from './products' 
import usersModule from './users' 
import tagsModule from './tags' 
import brandModule from './brands'
import serverModule from './configuration'
import gameCategoryModule from './gamesCategory'
import customerModule from './customers'
import settingsDefault from './settingsDefault'
import synchronizeReportsModule from './synchronizeReports'
import betcoreBanners from './betcoreBanners'
import filesModule from './files'
import currenciesModules from './currencies'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    usersModule,
    moduleProducts,
    tagsModule,
    currenciesModules,
    brandModule,
    gameCategoryModule,
    customerModule,
    synchronizeReportsModule,
    betcoreBanners,
    filesModule,
    serverModule,
    settingsDefault
  },
  strict: process.env.DEV,
})
