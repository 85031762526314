
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        obj: {}
    },
    mutations: {
        GET_SERVER(state, payload){
            state.obj = payload
        },
    },
    actions: {
        getServers({commit}) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await axios.get('servers/list')
                    commit('GET_SERVER', response.data)
                    resolve(response)
                } catch (error) {
                    return reject(error)
                }
            })
        },
        addServer({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`servers/create`, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        updateServer({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`servers/update/${payload._id}`, payload)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        removeServer({commit}, o) {
            return new Promise((resolve, reject) => {
                return axios.post(`servers/delete/${o.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        getProducts({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post('/pages/products', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        importData({commit}, o) {
            return new Promise((resolve, reject) => {
                return axios.get(o.url)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
