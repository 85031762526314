<template>
  <b-card>
  <modalShowConfig ref="modalShowConfig"/>
  <modalShowProducts ref="modalShowProducts"/>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col md="6"></b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button
          class="text-nowrap"
          variant="primary"
          @click="activeSidebarAddCustomer = true"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap">Add Customer</span>
        </b-button>
        <b-sidebar
          id="sidebar-addEditCustomer"
          bg-variant="white"
          right
          backdrop
          shadow
          no-header
          :visible="activeSidebarAddCustomer"
          @change="changeSidebarActive"
        >
          <addEditCustomer
            ref="addEditCustomer"
            :activeSidebarAddCustomer="activeSidebarAddCustomer"
            @hiddentSidebar="activeSidebarAddCustomer = false"
            @getCustomers="getCustomers()"
            @showConfig="showConfig"
          />
        </b-sidebar>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="customerOptions"
      :sort-options="{ enabled: false }"
     
      :pagination-options="{
        enabled: false,
      }"
      styleClass="vgt-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'status'">
          <b-badge
            pill
            :variant="`light-${props.row.status ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            {{ props.row.status ? "Active" : "Inactive" }}
          </b-badge>
        </div>
        

        <div v-else-if="props.column.field == 'products'">
          <b-button
            variant="outline-primary"
            @click="showProducts(props.row)"
            size="sm"
          >
            {{  props.row.products.length }} Products
          </b-button>
        </div>

        <div v-else-if="props.column.field == 'credit'">
          <b-badge
            v-if="props.row.limitUSD > 0"
            pill
            :variant="`light-${props.row.limitUSD > props.row.limitUSDUsed ? 'success' : 'danger'}`"
            class="text-capitalize"
          >
            <span v-if="props.row.limitUSDUsed > 0">
              ${{ props.row.limitUSDUsed.toFixed(4) }}
              /
            </span>
            <span v-else>
              $0
              /              
            </span>
            <span>
            ${{ props.row.limitUSD }}
            </span>
          </b-badge>
        </div>

        <div v-else-if="props.column.field == 'actions'">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editcustomer(props.row)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(props.row)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            <b-dropdown-item @click="editProducts(props.row)">
              <feather-icon icon="ChevronsUpIcon" />
              <span class="align-middle ml-50">Update Products</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showConfig(props.row)">
              <feather-icon icon="SettingsIcon" />
              <span class="align-middle ml-50">Show Config</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="showProduct(props.row)">
              <feather-icon icon="BoxIcon" />
              <span class="align-middle ml-50">Products</span>
            </b-dropdown-item> -->
            <b-dropdown-item @click="showGames(props.row)">
              <feather-icon icon="CpuIcon" />
              <span class="align-middle ml-50">Games</span>
            </b-dropdown-item>

          </b-dropdown>
        </div>
      </template>
    </vue-good-table>
    <section style="padding: 30px 0 0px 0px;">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              first-number
              @change="getCustomers"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" /> 
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <games ref="games" @updateListCustomer="getCustomers" :customer="customerSelected" :customers="customers"/>
      <!-- <products ref="products"/> -->
  </b-card>
</template>

<script>
import Games from './games.vue';
import Products from './productsLock.vue';

import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import customersStoreModule from "@/store/customers";
import addEditCustomer from "./addEditCustomer";
import store from "@/store";
import Ripple from 'vue-ripple-directive'

import modalShowConfig from "@/views/pagesIntegrations/customer/modalShowConfig";
import modalShowProducts from "@/views/pagesIntegrations/customer/modalShowProducts";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    addEditCustomer,
    modalShowConfig,
    modalShowProducts,
    Games,
    Products
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    columns: [
      { label: "Name", field: "name" },
      { label: "Credit", field: "credit" },
      { label: "Status", field: "status" },
      { label: "Products", field: "products" },
      { label: "Actions", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    customerSelected: {},
    customerOptions: [],
  }),
  computed: {
    customers() {
      return this.$store.state.customersModule.customers;
    },
  },
  watch: {
    searchQuery() {
      this.getCustomers();
    },
  },
  methods: {
    async getCustomers(page = null) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("customerModule/getCustomersSearch", {
              q: this.searchQuery,
              paginate: {
                         page: page !== null ? page : this.current_page,
                         perPage: this.per_page,
                         sortDesc: true 
                        } 
          
        });
        this.customerOptions = res.data.customers;
        this.total = res.data.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching customers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    confirmDelete(val) {
      this.$bvModal
        .msgBoxConfirm(`Do you want to remove the ${val.name} customer?`, {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteCustomer(val);
        });
    },
    async deleteCustomer(val) {
      try {
        this.loadingData = true;
        await this.$store.dispatch("customerModule/deleteCustomer", val._id);
        this.getCustomers();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Customer successfully removed",
            icon: "CheckCircleIcon",
            variant: "success",
            text: `${error.response.data.message}`,
          },
        });

      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching customers list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    editcustomer(customer) {
      this.activeSidebarAddCustomer = true;
      this.$refs.addEditCustomer.modeEdit = true;
      this.$refs.addEditCustomer.customer = {
        _id: customer._id,
        name: customer.name,
        urlWallet: customer.urlWallet,
        hashKey: customer.hashKey,
        status: customer.status,
        credentials: customer.credentials,
        limitUSD: customer.limitUSD,
      };
      
    },
    editProducts(customer){
       customer.productCreditLimits?.forEach(val => {
        this.$refs.addEditCustomer.productCreditLimits[val.productId] = val.creditLimit
      })

      let productsSelect = customer.products.map(val => val._id)
      this.activeSidebarAddCustomer = true;
      this.$refs.addEditCustomer.editProducts = true;
      this.$refs.addEditCustomer.customer = {
        id: customer._id,
        name: customer.name,
        products: productsSelect
      };
    },
    changeSidebarActive(val) {
      if (!val) this.$refs.addEditCustomer.modeEdit = false;
      this.activeSidebarAddCustomer = val;
    },
    showConfig(customer){
      this.$refs.modalShowConfig.token = customer.token
      this.$refs.modalShowConfig.customerId = customer._id
      this.$refs.modalShowConfig.showConfig(customer)
    },
    showProducts(customer){
      this.$refs.modalShowProducts.show(customer)
    },
    showGames(customer){
      this.customerSelected = customer
      // this.$refs.games.customer = JSON.parse(JSON.stringify(customer) )
      // this.$refs.games.customer = customer
      this.$refs.games.modalShow = true
    },
    showProduct(user){
      this.$refs.products.user = JSON.parse(JSON.stringify(user) )
      this.$refs.products.modalShow = true
    },
  },
  mounted() {
    this.getCustomers();
  },
  created() {
    // Register module
    if (!store.hasModule("customersModule")) store.registerModule("customersModule", customersStoreModule);
  },
  destroyed() {
    if (store.hasModule("customersModule")) store.unregisterModule("customersModule");  
  },
};
</script>

<style lang="scss" >
.per-page-selector {
  width: 90px;
}

#sidebar-addEditCustomer {
  min-width: 500px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

