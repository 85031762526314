<template>
  <b-card>
    <b-row>
      <b-col md="4">
        <label>Proveedor</label>
        <b-overlay :show="loadingDataProducts" rounded="lg" opacity="0.6">
          <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="secondary" />
              <b-spinner type="grow" variant="dark" />
              <b-spinner small type="grow" variant="secondary" />
              <span class="sr-only">Please wait...</span>
            </div>
          </template>

          <v-select
            class="mb-1"
            v-model="producSelectId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productsActivesOpctions"
            label="name"
            @input="productSelectInput"
            :reduce="(product) => product._id"
            placeholder="Select product"
          >
          </v-select>
        </b-overlay>
      </b-col>

      <b-col md="4">
        <label>Marcas</label>
        <b-overlay :show="loadingDataBrands" rounded="lg" opacity="0.6">
          <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="secondary" />
              <b-spinner type="grow" variant="dark" />
              <b-spinner small type="grow" variant="secondary" />
              <span class="sr-only">Please wait...</span>
            </div>
          </template>

          <v-select
            class="mb-1"
            v-model="brandId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="brandsOptions"
            label="name"
            :reduce="(brand) => brand.brandId"
            @input="brandSelectInput"
            placeholder="Select brand"
          >
          </v-select>
        </b-overlay>
      </b-col>

      <b-col
        md="4"
        class="d-flex align-items-start justify-content-end mb-1 mt-2"
      >
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button
          class="text-nowrap"
          variant="primary"
          @click="activeSidebarAddGame = true"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap">Add Game</span>
        </b-button>
        <b-sidebar
          sidebar-class="sidebar-lg"
          id="sidebar-addEditGame"
          bg-variant="white"
          right
          backdrop
          shadow
          no-header
          :visible="activeSidebarAddGame"
          @change="changeSidebarActive"
        >
          <addEditGame
            ref="addEditGame"
            :activeSidebarAddGame="activeSidebarAddGame"
            @hiddentSidebar="activeSidebarAddGame = false"
            @getGames="getGames()"
          />
        </b-sidebar>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex align-items-start justify-content-end">
        <b-button
          v-if="producSelectId"
          :variant="
            operationDeactiveActive == 'activate'
              ? 'outline-success'
              : 'outline-danger'
          "
          @click="desactiveActiveGames('products')"
          class="d-inline-block mb-1 mr-1"
          size="sm"
        >
          <feather-icon
            :icon="
              operationDeactiveActive == 'activate'
                ? 'CheckCircleIcon'
                : 'XCircleIcon'
            "
          />
          <span class="align-middle ml-25"
            >{{
              operationDeactiveActive == "activate" ? "Activar" : "Desactivar"
            }}
            todo el proveedor</span
          >
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex align-items-start justify-content-end">
        <b-button
          v-if="brandId"
          :variant="
            operationDeactiveActive == 'activate'
              ? 'outline-success'
              : 'outline-danger'
          "
          @click="desactiveActiveGames('brands')"
          class="d-inline-block mb-1"
          size="sm"
        >
          <feather-icon
            :icon="
              operationDeactiveActive == 'activate'
                ? 'CheckCircleIcon'
                : 'XCircleIcon'
            "
          />
          <span class="align-middle ml-25"
            >{{
              operationDeactiveActive == "activate" ? "Activar" : "Desactivar"
            }}
            toda la marca</span
          >
        </b-button>
      </b-col>
    </b-row>

    <!-- table -->
    <b-overlay :show="loadingData" variant="transparent">
      <vue-good-table
        :columns="columns"
        :rows="games"
        :sort-options="{ enabled: false }"
        :pagination-options="{
          enabled: false,
          mode: 'pages',
          perPage: this.per_page,
          perPageDropdown: [20, 40, 60, 100],
        }"
        styleClass="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'status'">
            <b-badge
              pill
              :variant="`light-${props.row.status ? 'success' : 'danger'}`"
              class="text-capitalize cursor-pointer"
              @click="changeStatus(props.row)"
            >
              {{ props.row.status ? "Active" : "Inactive" }}
            </b-badge>
          </div>
          <div v-else-if="props.column.field == 'image'">
            <!-- con sombra de color verde bootstrap-->
            <img
              v-if="props.row.url_image != null"
              width="115"
              height="100"
              :src="props.row.url_image"
              :alt="props.row.url_image"
              class="shadow-lg rounded"
              @click="showImage(props.row)"
              v-b-modal="'viewImageGame'"
            />
            <img
              v-else
              src="https://via.placeholder.com/115x100"
              width="115"
              height="100"
              :alt="props.row.name"
              class="shadow-lg rounded"
            />
          </div>

          <div v-else-if="props.column.field == 'tags'">
            <ul v-if="props.row.tags.length > 0">
              <li v-for="(tr, index) in props.row.tags" :key="index">
                {{ tr }}
              </li>
            </ul>
            <p class="ml-2" v-else>-</p>
          </div>
          <div v-else-if="props.column.field == 'categories'">
            <ul v-if="props.row.categories && props.row.categories.length > 0">
              <li v-for="(tr, index) in props.row.categories" :key="index">
                {{ tr.name }}
              </li>
            </ul>
            <p class="ml-2" v-else>-</p>
          </div>

          <!-- brandId -->
          <div v-else-if="props.column.field == 'brandId'">
            <p v-if="props.row.brandId">{{ props.row.brandId.name }}</p>
            <p class="ml-2" v-else>-</p>
          </div>

          <div v-else-if="props.column.field == 'actions'">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="editProduct(props.row)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmDelete(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>

              <b-dropdown-item @click="showModalUploadImages(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Upload Images</span>
              </b-dropdown-item>

              <b-dropdown-item @click="showModalOpenGame(props.row)">
                <feather-icon icon="ArrowUpCircleIcon" />
                <span class="align-middle ml-50">Open Game</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <div slot="emptystate" class="text-center">
          <b-badge
            pill
            :variant="`light-${producSelectId ? 'danger' : 'primary'}`"
            class="text-capitalize"
          >
            {{
              producSelectId
                ? "There are no games in the selected product"
                : "You must select a product"
            }}
          </b-badge>
        </div>
      </vue-good-table>
      <section style="padding: 30px 0 0px 0px">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              first-number
              @change="getGames"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
    <!-- view image game -->
    <b-modal
      id="viewImageGame"
      hide-footer
      modal-class="modal-primary"
      centered
      :title="
        'View Image' +
        (gameSelected.gameName ? ' - ' + gameSelected.gameName : '')
      "
    >
      <!-- <img v-if="gameSelected.url_image != null" width="100%" height="100%" :src="gameSelected.url_image"
        :alt="gameSelected.url_image" class="rounded" />
      <img v-else src="https://via.placeholder.com/100x100" width="100%" height="100%" :alt="gameSelected.name" /> -->

      <div>
        <b-carousel
          id="carousel-fade"
          style="text-shadow: 1px 1px 2px #333"
          fade
          indicators
          img-width="1024"
          img-height="480"
        >
          <b-carousel-slide
            v-for="(image, index) in gameSelected.listImages"
            :key="index"
            :caption="image.key"
            :img-src="image.url"
          />
        </b-carousel>
      </div>
    </b-modal>

    <OpenGame ref="openGame" />
    <UploadImagesGames @getGames="getGames()" ref="uploadImagesGames" />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BSpinner,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import gamesStoreModule from "@/store/games";
import productStoreModule from "@/store/products";
import addEditGame from "./addEditGame";
import store from "@/store";
import UploadImagesGames from "./uploadImage/index.vue";
import OpenGame from "./openGame.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BSpinner,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    addEditGame,
    UploadImagesGames,
    BCarousel,
    BCarouselSlide,
    OpenGame,
  },
  data: () => ({
    current_page: 1,
    total: 0,
    per_page: 10,
    loadingData: false,
    loadingDataProducts: false,
    producSelectId: "",
    searchQuery: "",
    timeOutSearch: null,
    activeSidebarAddGame: false,
    games: [],
    showImages: false,
    gameSelected: {},
    loadingDataBrands: false,
    brandId: "",
    brandsOptions: [],
    operationDeactiveActive: "activate",
  }),
  computed: {
    brandsList() {
      return this.$store.state.brandModule.brands;
    },
    productsActivesOpctions() {
      return this.$store.getters["productsModule/productsActives"];
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    columns() {
      let columns = [
        { label: "Image", field: "image" },
        { label: "Name", field: "gameName" },
        { label: "Game ID", field: "gameId" },
        { label: "Brand", field: "brandId" },
        { label: "Categories", field: "categories" },
        { label: "Status", field: "status" },
        { label: "Tags", field: "tags" },
      ];

      if (this.typeUserLoged == "Root") {
        columns.push({ label: "Actions", field: "actions" });
      }
      return columns;
    },
  },
  watch: {
    $route(to, from) {
      this.getGames();
      this.getBrands();
    },
    producSelectId(val, oldVal) {
      this.current_page = 1;
      this.total = 0;
      this.per_page = 10;
    },
    brandId(val) {
      this.current_page = 1;
      this.total = 0;
      this.per_page = 10;
    },
    searchQuery(val) {
      clearTimeout(this.timeOutSearch);
      this.timeOutSearch = setTimeout(() => {
        this.getGames();
      }, 800);
    },
  },
  methods: {
    showModalUploadImages(game) {
      this.$refs.uploadImagesGames.showModalUploadImages(game);
    },
    showModalOpenGame(game) {
      this.$refs.openGame.openModal = true;
      this.$refs.openGame.resGame = game;
      this.$refs.openGame.dataCustomer();
      this.$refs.openGame.resetModalData();
    },
    brandSelectInput(val) {
      // colocar minuscula
      const brandId = val ? val.toLowerCase() : null;
      // cambiar el paramas de la url sin hacer push, sin hacer replace
      this.$router.push({
        params: {
          brandId: brandId,
          producSelectId: this.producSelectId,
        },
      });
    },
    productSelectInput(val) {
      // set brands options
      // const productFind = this.productsActivesOpctions.find(product => product._id == val)
      // if(productFind){
      //   this.getBrands(val)
      // }else{
      //   this.brandsOptions = []
      // }

      const searchBrand = this.brandsOptions.find(
        (brand) => brand == this.brandId
      );
      if (!searchBrand) {
        this.brandId = null;
      }

      this.$router.push({
        params: {
          brandId: this.brandId,
          producSelectId: val,
        },
      });
    },
    async getGames(page = null) {
      this.producSelectId = this.$route.params.producSelectId;
      this.brandId = this.$route.params.brandId;

      try {
        if (!this.$route.params.producSelectId) {
          this.games = [];
          return false;
        }
        this.loadingData = true;

        const res = await this.$store.dispatch("gamesModule/getGames", {
          producSelectId: this.producSelectId,
          params: {
            page: page !== null ? page : this.current_page,
            perPage: this.per_page,
            q: this.searchQuery,
            brandId: this.brandId,
          },
        });
        this.games = res.data.games;
        this.total = res.data.count;

        // contar los juegos que estan activos y los que estan inactivos
        const gamesActives = this.games.filter((game) => game.status == true);
        const gamesInactives = this.games.filter(
          (game) => game.status == false
        );
        // setear el total de juegos activos e inactivos

        this.operationDeactiveActive =
          gamesActives.length > gamesInactives.length ? "inactive" : "activate";
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching games list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        if (!this.producSelectId) {
          setTimeout(() => {
            this.loadingData = false;
          }, 500);
        } else {
          this.loadingData = false;
        }
      }
    },
    confirmDelete(val) {
      this.$bvModal
        .msgBoxConfirm(`Do you want to remove the ${val.gameName} game?`, {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.deleteGame(val);
        });
    },
    async deleteGame(val) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("gamesModule/deleteGame", {
          productId: val.product._id,
          gameId: val.gameId,
        });
        this.getGames();
        if (res?.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Game successfully removed",
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to delete the game",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `An error has occurred, please contact the administrator`,
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },

    editProduct(val) {
      this.activeSidebarAddGame = true;
      this.$refs.addEditGame.modeEdit = true;

      this.$refs.addEditGame.producSelectId = val?.product?._id;
      this.$refs.addEditGame.game = {
        gameID: val?.gameId,
        gameName: val?.gameName,
        brandId: val?.brandId?._id,
        categories: val?.categories.map((val) => val?._id),
        product: val?.gameName,
        status: val?.status,
        product: val?.product?._id,
        tags: val?.tags,
        url_image: val?.url_image,
      };
      this.$refs.addEditGame.url_image = val?.url_image;
      this.$refs.addEditGame.gameOld = {
        gameID: val?.gameId,
        gameName: val?.gameName,
        product: val?.gameName,
        status: val?.status,
        product: val?.product?._id,
        tags: val?.tags,
      };
    },
    changeSidebarActive(val) {
      if (!val) this.$refs.addEditGame.modeEdit = false;
      this.activeSidebarAddGame = val;
    },
    async getProducts() {
      try {
        this.loadingDataProducts = true;
        this.showImages = false;
        await this.$store.dispatch("productsModule/getProducts");

        setTimeout(() => {
          this.showImages = true;
        }, 1000);

        // set brands options
        // const productFind = this.productsActivesOpctions.find(product => product._id == this.producSelectId)
        // if(productFind){
        //   // this.brandsOptions = productFind.brands
        //   this.getBrands()
        // }else{
        //   this.brandsOptions = []
        // }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching products list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataProducts = false;
      }
    },
    showImage(game) {
      console.log(game);
      let listImages = [];
      listImages.push({
        key: "url_image",
        url: game.url_image,
      });
      if (game.imageCollection) {
        Object.keys(game.imageCollection).forEach((key) => {
          listImages.push({
            key: key,
            url:
              game.imageCollection[key] ||
              "https://via.placeholder.com/100x100",
          });
        });
      }
      this.gameSelected = game;
      this.gameSelected.listImages = listImages;
    },
    async getBrands() {
      try {
        if (this.$route.params.producSelectId) {
          this.brandsOptions = [];
        }
        this.loadingDataBrands = true;
        const dataBrands = await this.$store.dispatch(
          "brandModule/index",
          this.$route.params.producSelectId
        );

            this.brandsOptions = dataBrands.data.brands
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching brands list",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.response.data.message}`,
            },
          });
        } finally {
          this.loadingDataBrands = false;
        }
      },
      async changeStatus(val) {
        try {
          this.loadingData = true;
          const gameData = {
            status: !val.status,
          }
          await this.$store.dispatch("gamesModule/changeStatus", {gameDataOld: {product: val.product._id, gameID: val.gameId}, gameData: gameData});
          this.getGames();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Game successfully updated",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `Status updated successfully`,
            },
          });
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update the game",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `An error has occurred, please contact the administrator`,
            },
          });
        } finally {
          this.loadingData = false;
        }
      },
      async desactiveActiveGames(type){
        try {

        const productFind = this.productsActivesOpctions.find(product => product?._id == this.producSelectId)
        const brandFind = this.brandsOptions.find(brand => brand.brandId == this.brandId)
        const textConfirm = type == 'brands' ? `Seguro quieres ${this.operationDeactiveActive == 'activate' ? 'activar' : 'desactivar'} todos los juegos de la marca ${this.brandId}` : `Seguro quieres  ${this.operationDeactiveActive == 'activate' ? 'activar' : 'desactivar'}  todos los juegos del producto ${productFind.name}`

        this.brandsOptions = dataBrands.data.brands;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching brands list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataBrands = false;
      }
    },
    async desactiveActiveGames(type) {
      try {
        const productFind = this.productsActivesOpctions.find(
          (product) => product?._id == this.producSelectId
        );
        const brandFind = this.brandsOptions.find(
          (brand) => brand.brandId == this.brandId
        );
        const textConfirm =
          type == "brands"
            ? `Seguro quieres ${
                this.operationDeactiveActive == "activate"
                  ? "activar"
                  : "desactivar"
              } todos los juegos de la marca ${this.brandId}`
            : `Seguro quieres  ${
                this.operationDeactiveActive == "activate"
                  ? "activar"
                  : "desactivar"
              }  todos los juegos del producto ${productFind.name}`;

        this.$bvModal
          .msgBoxConfirm(textConfirm, {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then(async (value) => {
            if (!value) return false;

            this.loadingData = true;
            await this.$store.dispatch("gamesModule/desactiveActiveGames", {
              productId: this.producSelectId,
              brandId: brandFind?._id,
              type,
              operation: this.operationDeactiveActive,
            });
            this.getGames();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Games successfully updated",
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Games successfully updated`,
              },
            });
          });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Failed to update games",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `An error has occurred, please contact the administrator`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
  mounted() {
    this.getProducts();
    this.getBrands();
    if (this.$route.params.producSelectId) {
      this.getGames();
    }
  },
  created() {
    // Register module
    if (!store.hasModule("gamesModule"))
      store.registerModule("gamesModule", gamesStoreModule);

    if (!store.hasModule("productsModule"))
      store.registerModule("productsModule", productStoreModule);
  },
  destroyed() {
    if (store.hasModule("gamesModule")) store.unregisterModule("gamesModule");
    if (store.hasModule("productsModule"))
      store.unregisterModule("productsModule");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
