import axios from "axios";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLogScan({ commit }) {
      return new Promise((resolve, reject) => {
        return axios
          .get(`report/scan-logs-report`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    syncReport({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`report/sync-report-summary`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
