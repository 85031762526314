<template>
    <div>
    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('labels.search')"
          />
          <b-button
            v-if="typeUserLoged == 'Root'"
            class="text-nowrap"
            variant="primary"
            @click="activeSidebarAddBrand = true"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">{{$t('addbrand')}}</span>
          </b-button>
          <b-sidebar
            sidebar-class="sidebar-lg"
            id="sidebar-addEditBrand"
            bg-variant="white"
            right
            backdrop
            shadow
            no-header
            :visible="activeSidebarAddBrand"
            @change="changeSidebarActive"
          >
            <addEditBrand
              ref="addEditBrand"
              @hiddentSidebar="activeSidebarAddBrand = false"
              @getBrands="getBrands()"
            />
          </b-sidebar>
        </b-col>
      </b-row>
  
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="brandsList"
        :sort-options="{ enabled: false }"
        :search-options="{
          enabled: true,
          externalQuery: searchQuery,
        }"
        styleClass="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'status'">
            <b-badge
              pill
              :variant="`light-${props.row.status ? 'success' : 'danger'}`"
              class="text-capitalize"
            >
              {{ props.row.status ? "Active" : "Inactive" }}
            </b-badge>
          </div>

          <div v-else-if="props.column.field == 'url_image'">
          <!-- con sombra de color verde bootstrap-->
          <img
            v-if="props.row.url_image != null"
            width="115"
            height="100"
            :src="props.row.url_image"
            :alt="props.row.url_image"
            class="shadow-lg rounded"
            @click="urlImageShow = props.row.url_image"
            v-b-modal="'viewImageBrand'"
          />
          <img
            v-else
            src="https://via.placeholder.com/115x100"
            width="115"
            height="100"
            :alt="props.row.name"
            class="shadow-lg rounded" 
          />

        </div>

          <div v-else-if="props.column.field == 'createdAt'">
            <div>{{ formatDate(props.row.createdAt) }}</div>
          </div>
  
          <div v-else-if="props.column.field == 'actions'">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item v-if="typeUserLoged == 'Root'" @click="editBrand(props.row)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
  
              <b-dropdown-item v-if="typeUserLoged == 'Root'" @click="confirmDelete(props.row)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal
      id="viewImageBrand"
      hide-footer
      modal-class="modal-primary"
      centered
      title="View Image"
    >
      <img
        v-if="urlImageShow != null"
        width="100%"
        height="100%"
        :src="urlImageShow"
        :alt="urlImageShow"
        class="rounded"
      />
      <img
        v-else
        src="https://via.placeholder.com/100x100"
        width="100%"
        height="100%"
        :alt="urlImageShow"
      />
    </b-modal>

    
      </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBToggle,
    VBModal,
    BCardText,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BProgress,
    BSpinner,
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { VueGoodTable } from "vue-good-table";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import addEditBrand from "./addEditBrand";
  import { formatDate } from "@core/utils/filter";
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BProgress,
      BCard,
      BRow,
      BCol,
      BTable,
      BOverlay,
      BPagination,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BInputGroup,
      BCollapse,
      BSpinner,
      VBModal,
      BCardText,
      VBToggle,
      vSelect,
      VueGoodTable,
      ToastificationContent,
      BBadge,
      BDropdown,
      BDropdownItem,
      BSidebar,
      addEditBrand,
    },
    data: () => ({
      showCredentials: false,
      processing: false,
      showProcessImportApiGame: false,
      loadingData: false,
      searchQuery: "",
      activeSidebarAddBrand: false,
      urlImageShow: null,
    }),
    computed: {
      columns(){
        let columns = [
          { label: 'Logo', field: "url_image" },
          { label: 'BrandId', field: "brandId" },
          { label: 'Name', field: "name" },
          { label: "Description", field: "description" },
          { label: 'Status', field: "status" },
        ]
  
        if(this.typeUserLoged == 'Root' || this.typeUserLoged == 'admin'){
          columns.push({ label: 'Actions', field: "actions" })
        }
  
        return columns
      },
      brandsList() {
        return this.$store.state.brandModule.brands;
      },
      typeUserLoged() {
        return this.$store.getters["usersModule/typeUser"];
      },
    },
    methods: {
      formatDate(fecha) {
        const dt = new Date(fecha);
        return formatDate(dt);
      },
      async getBrands() {
        try {
          this.loadingData = true;
          await this.$store.dispatch("brandModule/index");
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching brands list",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.response.data.message}`,
            },
          });
        } finally {
          this.loadingData = false;
        }
      },
      confirmDelete(val) {
        this.$bvModal
          .msgBoxConfirm(this.$t('sure_delete_brand'), {
            title: this.$t('delete_brand'),
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) this.deleteBrand(val);
          });
      },
      async deleteBrand(val) {
        try {
          this.loadingData = true;
          await this.$store.dispatch("brandModule/delete", val._id);
          this.getBrands();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('brand_deleted'),
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
        } catch (error) {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('error_delete_brand'),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.response?.data?.message}`,
            },
          });
        } finally {
          this.loadingData = false;
        }
      },
  
      editBrand(val) {
        this.activeSidebarAddBrand = true;
        this.$refs.addEditBrand.edit(val)
      },
      changeSidebarActive(val) {
        if (!val) this.$refs.addEditBrand.modeEdit = false;
        this.activeSidebarAddBrand = val;
      },
    },
    mounted() {
      this.getBrands();
    },
  };
  </script>
  
  <style lang="scss" >
  .per-page-selector {
    width: 90px;
  }
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-good-table.scss";
  </style>
  
  