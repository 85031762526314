<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col md="6"></b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
        <b-button @click="openModal = !openModal" class="text-nowrap" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="text-nowrap">{{ $t("new_user") }}</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="users.users" :sort-options="{ enabled: false }" :pagination-options="{
      enabled: false
    }" :search-options="{
        enabled: true,
        externalQuery: searchQuery,
      }" styleClass="vgt-table bordered responsive" theme="default">
      <template slot="table-row" slot-scope="props">
        <!-- Name Column -->
        <div v-if="props.column.field == 'namePlayer'" class="d-flex align-items-center">
          <b-avatar v-if="props.row.name && props.row.lastname"
            :text="props.row.name.charAt(0) + props.row.lastname.charAt(0)" variant="light-primary" size="32"
            class="mr-2" />
          <div>
            <div class="font-weight-bold">{{ props.row.name }} {{ props.row.lastname }}</div>
            <small class="text-muted">@{{ props.row.username }}</small>
          </div>
        </div>

        <!-- Customers Column -->
        <div v-else-if="props.column.field == 'customers'" class="customers-cell">
          <template v-if="props.row.customersList && props.row.customersList.length">
            <div class="d-flex flex-wrap align-items-center">

              <b-badge v-for="(customer, index) in props.row.customersList" :key="index" variant="light-primary"
                class="mr-1 mb-1">
                {{ customer.name }}
              </b-badge>
            </div>
          </template>
          <span v-else class="text-muted">-</span>
        </div>

        <!-- Created Date -->
        <div v-else-if="props.column.field == 'createdAt'" class="text-nowrap">
          <feather-icon icon="CalendarIcon" size="16" class="mr-1" />
          {{ props.row.createdAt | formatDate }}
        </div>

        <!-- User Type -->
        <div v-else-if="props.column.field == 'typeUser'">
          <b-badge :variant="getUserTypeVariant(props.row.typeUser)" class="text-capitalize">
            {{ props.row.typeUser }}
          </b-badge>
        </div>

        <!-- Status -->
        <div v-else-if="props.column.field == 'status'">
          <b-badge pill :variant="props.row.status ? 'light-success' : 'light-danger'">
            <feather-icon :icon="props.row.status ? 'CheckCircleIcon' : 'XCircleIcon'" size="16" class="mr-1" />
            {{ props.row.status ? "Active" : "Inactive" }}
          </b-badge>
        </div>

        <!-- Actions -->
        <div v-else-if="props.column.field == 'actions'" class="text-nowrap">
          <b-button-group size="sm">
            <b-button variant="flat-primary" v-b-tooltip.hover title="Edit User" @click="userEdit(props.row)">
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
            <b-button variant="flat-primary" v-b-tooltip.hover title="Change Password"
              @click="userChangePassword(props.row)">
              <feather-icon icon="KeyIcon" size="16" />
            </b-button>
          </b-button-group>
        </div>
      </template>
    </vue-good-table>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-between mt-3">
      <span class="text-muted">
        Showing {{ ((current_page - 1) * per_page) + 1 }} to {{ Math.min(current_page * per_page, total) }} of {{ total
        }}
        entries
      </span>
      <b-pagination v-model="current_page" :total-rows="total" :per-page="per_page" first-number @change="getUsers"
        class="mb-0" last-number prev-class="prev-item" next-class="next-item">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </div>

    <games ref="games" />
    <products ref="products" />
    <user-change-password ref="userChangePassword" />
    <b-modal no-close-on-backdrop v-model="openModal" hide-footer modal-class="modal-primary" centered
      :title="formData._id ? $t('edit_user') : $t('new_user')" size="lg">
      <validation-observer ref="Form" v-slot="{ handleSubmit }">
        <b-form class="p-2" @submit.prevent="handleSubmit(addUpdateUser)">
          <!-- Personal Information Section -->
          <h5 class="mb-2">Personal Information</h5>
          <b-row>
            <b-col md="6">
              <validation-provider name="First Name" rules="required|min:2" v-slot="{ errors }">
                <b-form-group label="First Name">
                  <b-form-input v-model="formData.name" :state="errors.length === 0" placeholder="Enter first name"
                    trim />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider name="Last Name" rules="required|min:2" v-slot="{ errors }">
                <b-form-group label="Last Name">
                  <b-form-input v-model="formData.lastname" :state="errors.length === 0" placeholder="Enter last name"
                    trim />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Account Information Section -->
          <h5 class="mb-2 mt-3">Account Information</h5>
          <b-row>
            <b-col md="6">
              <validation-provider name="Username" rules="required|min:4" v-slot="{ errors }">
                <b-form-group label="Username">
                  <b-form-input v-model="formData.username" :state="errors.length === 0" placeholder="Enter username"
                    trim />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" v-if="!formData._id">
              <validation-provider name="Password" :rules="formData._id ? '' : 'required|min:8'" v-slot="{ errors }">
                <b-form-group label="Password">
                  <b-input-group>
                    <b-form-input v-model="formData.password" :type="passwordFieldType"
                      :placeholder="formData._id ? 'Leave blank to keep current' : 'Enter password'"
                      :state="errors.length === 0" />
                    <b-input-group-append>
                      <b-button @click="togglePasswordVisibility" variant="outline-secondary">
                        <feather-icon :icon="passwordToggleIcon" size="16" />
                      </b-button>
                      <b-button v-b-tooltip.hover title="Generate Password" @click="randomPass"
                        variant="outline-primary">
                        <feather-icon icon="RefreshCwIcon" size="16" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Role & Access Section -->
          <h5 class="mb-2 mt-3">Role & Access</h5>
          <b-row>
            <b-col md="6">
              <validation-provider name="User Type" rules="required" v-slot="{ errors }">
                <b-form-group label="User Type">
                  <v-select v-model="formData.typeUser" :options="typeUserOptions" :reduce="item => item.value"
                    label="text" :clearable="false" :state="errors.length === 0" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" v-if="typeUserLoged === 'Root' && ['admin', 'operator'].includes(formData.typeUser)">
              <validation-provider name="Customers" rules="required" v-slot="{ errors }">
                <b-form-group label="Assigned Customers">
                  <v-select v-model="formData.customerIds" :options="customerOptions" label="name"
                    :reduce="item => item._id" multiple :state="errors.length === 0" @search="searchCustomer">
                    <template #no-options>
                      Type to search customers...
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <div class="d-flex mt-4">
            <b-button variant="primary" type="submit" :disabled="loadingData">
              <b-spinner small v-if="loadingData" />
              <span>{{ formData._id ? 'Update' : 'Create' }} User</span>
            </b-button>
            <b-button variant="outline-secondary" class="ml-2" @click="openModal = false">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BInputGroupPrepend,
  BSpinner,
  BButtonGroup,
  BAvatar,

} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import Games from "./games.vue";
import products from "./productsLock.vue";
import { required } from "@validations";
import UserChangePassword from "@/views/pagesIntegrations/users/userChangePassword";

export default {
  components: {
    UserChangePassword,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    Games,
    products,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSpinner,
    BButtonGroup,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    customerOptions: [],
    showPassword: false,
    required,
    openModal: false,
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    formData: {
      name: "",
      lastname: "",
      customerId: "",
      customerIds: '',
      username: "",
      password: "",
      typeUser: "",
    },
    columns: [
      { label: "Nombre", field: "namePlayer" },
      { label: "Username", field: "username" },
      { label: "Clientes", field: "customers" },
      { label: "Tipo de usuario", field: "typeUser" },
      { label: "Estado", field: "status" },
      { label: "Fecha creado", field: "createdAt" },
      { label: "Acciones", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    timeOutSearchCustomer: null,
  }),
  watch: {
    searchQuery() {
      this.getUsers();
    },
  },
  computed: {
    users() {
      return this.$store.state.usersModule.users;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    typeUserOptions() {
      if (this.typeUserLoged == "Root") {
        return [
          { text: "Super Admin", value: "superadmin" },
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      } else {
        return [
          { text: "Admin", value: "admin" },
          { text: "Operator", value: "operator" },
        ];
      }
    },
  },
  methods: {
    getUserTypeVariant(type) {
      switch (type) {
        case "Root":
          return "primary";
        case "admin":
          return "success";
        case "Admin":
          return "success";
        case "operator":
          return "warning";
        case "Agent":
          return "info";
        default:
          return "secondary";
      }
    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch(
            "customerModule/getCustomers",
            {
              q: search,
              paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true,
              },
            }
          );
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async dataCustomer() {
      try {
        const res = await this.$store.dispatch("customerModule/getCustomers");
        this.customerOptions = res.data.customers;
      } catch (error) {
        console.log(error);
      }
    },
    randomPass() {
      this.passwordFieldType = "text";
      var length = 15,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset?.charAt(Math.floor(Math.random() * n));
      }
      this.formData.password = retVal;
    },
    async addUpdateUser() {
      if (!(await this.$refs.Form.validate())) {
        return;
      }
      try {
        // Validamos si el usuario logeado es admin y si lo es le asignamos el customerId
        // if (this.$store.getters["usersModule/typeUser"] == "admin") {
        //   this.formData.customerId = this.$store.getters["usersModule/userLoged"].customerId;
        // }
        this.loadingData = true;
        try {
          if (this.formData._id) {
            // Update existing user
            await this.$store.dispatch("usersModule/updateUserAdmin", this.formData);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User updated successfully",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          } else {
            // Add new user 
            await this.$store.dispatch("usersModule/addUserAdmin", this.formData);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User added successfully",
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          }

          await this.getUsers();
          this.openModal = false;
          this.formData = {
            customerId: "",
            username: "",
            password: "",
          };
        } catch (error) {
          throw error;
        }
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add user admin",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async getUsers(page = 1) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("usersModule/getUsers", {
          q: this.searchQuery,
          paginate: {
            page: page,
            perPage: this.per_page,
            sortDesc: true,
          },
        });

        this.total = res.data.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    showGames(user) {
      this.$refs.games.user = JSON.parse(JSON.stringify(user));
      this.$refs.games.modalShow = true;
    },
    showProduct(user) {
      this.$refs.products.user = JSON.parse(JSON.stringify(user));
      this.$refs.products.modalShow = true;
    },
    userChangePassword(user) {
      this.$refs.userChangePassword.user = JSON.parse(JSON.stringify(user));
      this.$refs.userChangePassword.modalShow = true;
    },

    userEdit(user) {
      this.formData = {
        ...user,
        customerId: user.customerId,
        customerIds: user.customerIds,
      };

      console.log(this.formData, user);
      this.openModal = true;
    },
  },
  mounted() {
    this.getUsers();
    this.dataCustomer();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.form-group {
  margin-bottom: 0 !important;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
