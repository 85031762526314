<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(fullName)"
          :variant="`light-danger`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ fullName }}
      </h4>
      <div>
         <!-- username typeUser -->
        <span>
          Username: 
        </span>
        <span class="text-muted">
          {{ userData.username }}
        </span>
      </div>
      <div>
        <!-- email -->
        <span>
          Type User: 
        </span>
        <span class="text-muted">
          {{ userData.typeUser }}
        </span>
      </div>
    </b-media>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      avatarText,
      fullName: `${props.userData.name} ${props.userData.lastname}`,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
