<template>
  <b-card>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-row class="mb-1">
      <b-col md="6">
        <b-button class="text-nowrap" variant="primary" @click="syncCurrencies">
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span class="text-nowrap">Update rate</span>
        </b-button>
      </b-col>
      <b-col md="6" class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="currencies"
      :sort-options="{ enabled: false }"
      :search-options="{
        enabled: true,
        externalQuery: searchQuery,
      }"
      styleClass="vgt-table"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'currency'" class="margin-inline">
          <b-badge pill variant="light-primary">
            {{ props.row.currency }}
          </b-badge>
        </div>
        <div v-if="props.column.field == 'rate'" class="margin-inline">
          <b-badge pill variant="light-primary">
            {{ props.row.rate | currency({ symbol: "" }) }}
          </b-badge>
        </div>
        <div v-else-if="props.column.field == 'updatedAt'" class="margin-inline">
          <div>{{ formatDate(props.row.updatedAt) }}</div>
        </div>
        <div v-else-if="props.column.field == 'status'">
          <b-badge pill variant="light-success" class="text-capitalize"> Active </b-badge>
        </div>
        <div v-else-if="props.column.field == 'actions'">
          <b-button
            class="mr-2"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modalResponse
            variant="outline-primary"
            @click="getHistory(props.row)"
          >
            History
          </b-button>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modalResponse"
      :title="selectedCurrency.currency + ' History'"
      cancel-variant="outline-secondary"
      scrollable
    >
      <div class="card-transaction" no-body>
        <b-card-body>
          <div
            v-for="(transaction, index) in transactionData"
            :key="index"
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar rounded size="42" :variant="transaction.avatarVariant">
                  <feather-icon size="18" :icon="transaction.avatar" />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">{{ transaction.mode }}</h6>
                <small>{{ transaction.date | formatDate("DD/MM/YYYY HH:mm:ss") }}</small>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :class="transaction.deduction ? 'text-danger' : 'text-success'"
            >
              {{ transaction.rate | currency({ symbol: "" }) }}
            </div>
          </div>
        </b-card-body>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { formatDate } from "@core/utils/filter";
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BListGroup,
  BListGroupItem,
  BLink,
  VBTooltip,
  BForm,
  BInputGroupPrepend,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import UserChangePassword from "@/views/pagesIntegrations/users/userChangePassword";

export default {
  components: {
    UserChangePassword,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BButton,
    BInputGroup,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BLink,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // add filter
  filters: {
    formatDate,
  },
  mixins: [togglePasswordVisibility],
  data: () => ({
    transactionData: [
      {
        mode: "Update",
        date: "01/09/2023",
        avatar: "PocketIcon",
        avatarVariant: "light-primary",
        amount: "18,00",
        deduction: true,
      },
      {
        mode: "Update",
        date: "05/09/2023",
        avatar: "CheckIcon",
        avatarVariant: "light-success",
        amount: "13,21",
        deduction: false,
      },
      {
        mode: "Update",
        date: "10/09/2023",
        avatar: "DollarSignIcon",
        avatarVariant: "light-danger",
        amount: "99,01",
        deduction: false,
      },
      {
        mode: "Update",
        date: "12/09/2023",
        avatar: "CreditCardIcon",
        avatarVariant: "light-warning",
        amount: "10,23",
        deduction: true,
      },
      {
        mode: "Update",
        date: "15/09/2023",
        avatar: "TrendingUpIcon",
        avatarVariant: "light-info",
        amount: "21,01",
        deduction: false,
      },
    ],
    customerOptions: [],
    showPassword: false,
    required,
    selectedCurrency: {},
    openModal: false,
    currencies: [],
    loadingData: false,
    searchQuery: "",
    activeSidebarAddCustomer: false,
    formData: {
      name: "",
      lastname: "",
      customerId: "",
      username: "",
      password: "",
      typeUser: "",
    },
    columns: [
      { label: "Currency Name", field: "currency" },
      { label: "Exchange Rate", field: "rate" },
      { label: "Date Updated", field: "updatedAt" },
      { label: "Status", field: "status" },
      { label: "Actions", field: "actions" },
    ],
    current_page: 1,
    per_page: 10,
    total: 0,
    timeOutSearchCustomer: null,
  }),
  watch: {
    searchQuery() {
      this.getUsers();
    },
  },
  computed: {},

  methods: {
    async getHistory(row) {
      this.transactionData = [];
      this.selectedCurrency = row;
      let data = {
        symbol: row.currency,
      };
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("currenciesModules/history", {
          data,
        });
        this.transactionData = res.data;
        this.sortAndUpdateAvatars();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    parseDate(dateString) {
      const [day, month, year] = dateString.split("/");
      return new Date(`${year}-${month}-${day}`);
    },
    sortAndUpdateAvatars() {
      this.transactionData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      for (let i = 0; i < this.transactionData.length; i++) {
        const currentAmount = this.transactionData[i].rate;

        if (i === 0) {
          const nextAmount = this.transactionData[i + 1].rate;

          if (currentAmount < nextAmount) {
            this.transactionData[i].avatar = "TrendingDownIcon";
            this.transactionData[i].avatarVariant = "light-danger";
            this.transactionData[i].deduction = true;
          } else if (currentAmount > nextAmount) {
            this.transactionData[i].avatar = "TrendingUpIcon";
            this.transactionData[i].avatarVariant = "light-success";
            this.transactionData[i].deduction = false;
          } else {
            this.transactionData[i].avatar = "MinusIcon";
            this.transactionData[i].avatarVariant = "light-warning";
            this.transactionData[i].deduction = false;
          }
        } else {
          const previousAmount = this.transactionData[i - 1].rate;

          if (currentAmount < previousAmount) {
            this.transactionData[i].avatar = "TrendingDownIcon";
            this.transactionData[i].avatarVariant = "light-danger";
            this.transactionData[i].deduction = true;
          } else if (currentAmount > previousAmount) {
            this.transactionData[i].avatar = "TrendingUpIcon";
            this.transactionData[i].avatarVariant = "light-success";
            this.transactionData[i].deduction = false;
          } else {
            this.transactionData[i].avatar = "MinusIcon";
            this.transactionData[i].avatarVariant = "light-warning";
            this.transactionData[i].deduction = false;
          }
        }
      }
    },
    async syncCurrencies() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("currenciesModules/syncCurrencies");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: "success",
            text: `${res.message}`,
          },
        });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
        this.getCurrencies();
      }
    },
    async getCurrencies(page = 1) {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch("currenciesModules/getCurrencies");
        this.currencies = res;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
  mounted() {
    this.getCurrencies();
    this.sortAndUpdateAvatars();
  },
  setup() {
    return {
      formatDate,
    };
  },
};
</script>

<style lang="scss">
.margin-inline {
  margin-inline: 30px;
}

.per-page-selector {
  width: 90px;
}

.form-group {
  margin-bottom: 0 !important;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
