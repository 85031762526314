import axios from "axios"
export default {
  namespaced: true,
  state: {
    tags: [],
  },
  mutations: {},
  actions: {
    getTags({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.get('tags', {params: payload})
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },
    getGamesByTag({ commit }, id) {
      return new Promise((resolve, reject) => {
        return axios.get('games-tags/' + id)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
    },

    storeTag({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.post('tags/store', payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    updateTag({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.put('tags/update/'+ payload._id, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    getProducts({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.get('tags/products', { params: payload })
        .then(response => {
            resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    getGames({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.get('tags/games', { params: payload })
        .then(response => {
            resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    addTagGames({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.post(`/tags/${payload.tagId}/games`, payload.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    deleteTag({ commit }, tagId) {
      return new Promise((resolve, reject) => {
        return axios.delete(`/tags/${tagId}/delete`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    deleteTagGame({ commit }, {tagId, gameId}) {
      return new Promise((resolve, reject) => {
        return axios.delete(`/tags/${tagId}/delete/game/${gameId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
    updateIndexGames({ commit }, payload) {
      return new Promise((resolve, reject) => {
        return axios.put(`/tags/${payload.tagId}/update-index-games`, payload.data)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
      })
    },
  },
}
