
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        // customers: {
        //     customers: [],
        // },
    },
    getters: {},
    mutations: {
        // GET_GUSTOMER(state, payload){
        //     state.customers =  payload
        // },
        // ADD_CUSTOMER(state, payload){
        //     state.customers.push(payload)
        // }
    },
    actions: {
        getCustomers({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/pages/customers`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        getCustomersSearch({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/pages/customers`, payload)
                    .then(response => {
                        // commit('GET_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addCustomer({commit}, customer) {
            return new Promise((resolve, reject) => {
                return axios.post(`/customers`, customer)
                    .then(response => {
                        // commit('ADD_CUSTOMER', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        deleteCustomer({commit}, idCustomer) {
            return new Promise((resolve, reject) => {
                return axios.delete(`/customers/${idCustomer}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateCustomer({commit}, customer) {
            return new Promise((resolve, reject) => {
                return axios.put(`/customers/${customer._id}`, customer)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateProducts({commit}, customer){
            return new Promise((resolve, reject) => {
                return axios.post(`/customers/${customer.id}/products/`, customer)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })

        },
        blockGame({commit}, {objectIdCustomer, gameId, status}) {
            return new Promise((resolve, reject) => {
                return axios.post(`/customers/blockGame/${objectIdCustomer}`, {gameId, status})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        blockProduct({commit}, {userId, product, status}) {
            return new Promise((resolve, reject) => {
                return axios.post(`/productstatustoplayer/${userId}`, {product, status})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        setIsDemo({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/players-set-demo`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
