<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 v-if="!editProducts" class="mb-0">{{ modeEdit ? "Edit" : "New" }} customer</h5>
      <h5 v-else class="mb-0">Update Products {{ customer.name }}</h5>
    </div>
    <!-- BODY -->
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(submitOn)"
        @reset.prevent="resetForm"
      >
        <!-- Id -->
        <!--<validation-provider
          #default="validationContext"
          name="CustomerID"
          rules="required"
        >
          <b-form-group label="Customer Id" label-for="CustomerID">
            <b-form-input
              id="CustomerID"
              v-model="customer.customerId"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>-->

        <!-- Name -->
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="customerName"
          rules="required"
        >
          <b-form-group label="Customer Name" label-for="customerName">
            <b-form-input
              id="customerName"
              v-model="customer.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="urlWallet"
          rules="required"
        >
          <b-form-group class="mt-1" label="Wallet Url" label-for="urlWallet">
            <b-form-input
              id="urlWallet"
              v-model="customer.urlWallet"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              type="url"
            />
          </b-form-group>
        </validation-provider>
        
        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="hashKey"
        >
          <b-form-group class="mt-1" label="hashKey" label-for="hashKey">
            <b-form-input
              id="hashKey"
              v-model="customer.hashKey"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              type="text"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="!editProducts"
          #default="validationContext"
          name="urlWallet"
          rules="required"
        >
          <label for="limitUSD" class="mt-1">Crédito de apuesta</label>
          <b-input-group  prepend="$">
            <b-form-input
              id="limitUSD"
              v-model="customer.limitUSD"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              type="number"
            />
          </b-input-group>
        </validation-provider>


        <!--Products-->
        <div
          v-if="!modeEdit"
          class="d-flex justify-content-between align-items-center my-1"
        >
          <h5 class="mb-0">Select Products</h5>
          <b-button
            @click="showSeachProduct = !showSeachProduct"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
            size="sm"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>

        <b-form-input
          v-if="showSeachProduct"
          v-model="searchQueryProduct"
          class="d-inline-block mb-1"
          placeholder="Search Products..."
          size="sm"
        />

        
        <b-card
          v-if="!modeEdit"
          id="nav-scroller"
          ref="content"
          
          no-body
        >
        <b-list-group-item
            class="d-flex justify-content-between align-items-center font-weight-bold"
          >
            <span>Name</span>
            <span class="mr-5">Credit Bet</span>
          </b-list-group-item>

          <b-list-group
          style="position: relative; height: 300px; overflow-y: scroll"
          >
            <b-list-group-item
              v-for="(tr, index) in productsOptions"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              <span>{{ tr.name }}</span>

              <div 
                class="d-flex align-items-center">
                <div
                  v-if="customer.products.includes(tr._id)"
                >                
                <b-badge v-if="productCreditLimits[tr._id] < 1" variant="danger" class="mr-1">
                  No Credit
                </b-badge>
                <b-input-group 
                class="float-right mr-1" 
                style="width: 100px" 
                prepend="$"
                size="sm"                
                >
                  <b-form-input
                    :id="`creditPriduct${index}`"
                    v-model="productCreditLimits[tr._id]"
                    trim
                    type="number"
                  />
                </b-input-group>
              </div>

                <b-form-checkbox
                v-model="customer.products"
                :value="tr._id"
                class="custom-control-primary"
              />

              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-form-group v-if="!editProducts" label="Status" label-for="status">
          <b-form-checkbox
            v-model="customer.status"
            id="status"
            class="custom-control-success"
            switch
            inline
          >
            <b>{{ customer.status ? "Active" : "Unactive" }}</b>
          </b-form-checkbox>
        </b-form-group>

        <div v-if="!editProducts">
          <label>Token</label>
        <b-input-group label="Status" class="mb-2">
          <b-input-group-prepend>
            <b-form-checkbox
              v-model="customer.isToken"
              id="isToken"
              class="custom-control-success m-auto"
              switch
              inline
            />
          </b-input-group-prepend>
          <b-form-input
            v-if="customer.isToken"
            v-model="customer.token"
            placeholder="Enter token"
          />
        </b-input-group>
        </div>

        <!-- Credentials Products -->
        <validation-provider
          #default="validationContext"
          name="CredentialsProducts"
          v-if="!editProducts"
        >
          <b-form-group :label="$t('CredentialsProviders')" label-for="credentials">
            <v-jsoneditor v-model="customer.credentials" :plus="true"  height="350px" :options="configOptionsEditJson"/>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex align-items-center justify-content-between mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="modeEdit ? 'success' : 'primary'"
            class="mr-2"
            type="submit"
          >
            <feather-icon
              :icon="modeEdit ? 'RefreshCcwIcon' : 'PlusIcon'"
              class="mr-50"
            />
            <span class="align-middle">{{ modeEdit ? "Update" : "Add" }}</span>
          </b-button>
          <b-button
            @click="$emit('hiddentSidebar')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Cancel</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BOverlay,
  BCard,
  BCardBody,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormCheckboxGroup,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import productStoreModule from "@/store/products";
import customerStoreModule from "@/store/customers";
import store from "@/store";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VJsoneditor from 'v-jsoneditor/src/index'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BCard,
    BCardBody,
    BFormCheckboxGroup,
    vSelect,
    BOverlay,
    ToastificationContent,
    BListGroup,
    BListGroupItem,
    BBadge,
    BInputGroupPrepend,
    BInputGroup,
    VJsoneditor
  },
  directives: {
    Ripple,
  },
  props: {
    activeSidebarAddCustomer: { type: Boolean, required: true },
  },

  data() {
    return {
      required,
      modeEdit: false,
      editProducts: false,
      loadingData: false,
      showSeachProduct: false,
      searchQueryProduct: "",
      customer: {
        // customerId: "",
        urlWallet: "",
        hashKey: "",
        name: "",
        products: [],
        status: true,
        token: "",
        isToken: false,
      },
      configOptionsEditJson: {
        mode: 'code',     
        modes: ['code', 'tree'],  
        isExpand: true,
        height: 'auto',
        navigationBar: true,
      },
      productCreditLimits: [],
      listProducts: [],
    };
  },
  watch: {
    modeEdit(val) {
      if (!val) this.resetForm();
    },
    activeSidebarAddCustomer(val) {
      if (!val) {
        this.resetForm();
        this.editProducts = false;
      }
      if (val) {
        this.getProducts()
        this.getSettingsDefault();
      };
    },
  },
  computed: {
    productsOptions() {
      return this.listProducts?.filter((product) => {
        return product.name.toLowerCase().includes(this.searchQueryProduct.toLowerCase());
      });
    },
  },
  methods: {
    submitOn() {
        this.customer.productCreditLimits = Object.keys(this.productCreditLimits).map((key) => {
          return {
            productId: key,
            creditLimit: this.productCreditLimits[key],
          };
        });

        this.customer.productCreditLimits = this.customer.productCreditLimits.filter((tr) => {
          return tr.creditLimit > 0 && this.customer.products?.includes(tr.productId);
        });
      if (this.modeEdit) this.updateCustomer();
      else if (this.editProducts) this.updateProducts();
      else if (!this.modeEdit && !this.editProducts) this.saveCustomer();
    },
    // Save customer
    async saveCustomer() {
      try {
        this.loadingData = true;

        if (this.customer.urlWallet.slice(-1) === "/") {
          this.customer.urlWallet = this.customer.urlWallet.slice(0, -1);
        }

        const res = await this.$store.dispatch("customerModule/addCustomer", this.customer);
        this.resetForm();
        this.$emit("hiddentSidebar");
        this.$emit("getCustomers");
        this.$emit("showConfig", res.data);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Customer added successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error adding products add",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    // Save customer
    async updateCustomer() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("customerModule/updateCustomer", this.customer);
        this.modeEdit = false;
        this.$emit("hiddentSidebar");
        this.$emit("getCustomers");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Customer update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.productCreditLimits = [];
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error add customer",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async updateProducts() {
      try {
        this.loadingData = true;
        await this.$store.dispatch("customerModule/updateProducts", this.customer);
        this.$emit("hiddentSidebar");
        this.$emit("getCustomers");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Products update successfully",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
        this.productCreditLimits = [];
      } catch (error) {
        console.log(error.response.data.message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error update products",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    async getProducts() {
      const response = await this.$store.dispatch("productsModule/getProducts");

      this.listProducts = response.data;
      response.data.forEach((product) => {
        if (this.productCreditLimits[product._id]) {
          this.productCreditLimits[product._id] = this.productCreditLimits[product._id] || 0;
        }else{
          this.productCreditLimits[product._id] = 0
        }
      });

      // ordenar por creditLimit
      this.listProducts.sort((a, b) => {
        return this.productCreditLimits[a._id] === 0 ? 1 : -1; 
      });

      // ordenar por los que estan en customer.products
      this.listProducts.sort((a, b) => {
        return this.customer?.products?.includes(a._id) ? -1 : 1;
      });
    },

    async getSettingsDefault() {
      const response = await this.$store.dispatch("settingsDefault/index");
      response.data.productCreditLimits.forEach((tr) => {
        if(!this.customer?.products?.includes(tr.productId._id)){
        this.productCreditLimits[tr.productId._id] = tr.creditLimit; 
        }
      });
    },
    //---Validate State Fields
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {
      this.customer = {
        // customerId: "",
        name: "",
        products: [],
        status: true,
      };
      this.$refs.refFormObserver.reset();
    },
  },
  created() {
    // Register module
    if (!store.hasModule("customerModule"))
      store.registerModule("customerModule", customerStoreModule);
    if (!store.hasModule("productsModule"))
      store.registerModule("productsModule", productStoreModule);
  },
  destroyed() {
    if (store.hasModule("customerModule")) store.unregisterModule("customerModule");
    if (store.hasModule("productsModule")) store.unregisterModule("productsModule");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
