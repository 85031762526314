import axios from "axios";
import moment from "moment";
import momentTz from "moment-timezone";

function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLogsV2(
      { commit },
      {
        page,
        perPage,
        dateFrom,
        dateTo,
        q,
        gameId,
        selectProduct,
        selectType,
        sort,
      }
    ) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        perPage,
        q,
        page,
        gameId,
        selectProduct,
        selectType,
        sort,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`v2/connections`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getLogConnections(
      { commit },
      {
        page,
        perPage,
        dateFrom,
        dateTo,
        q,
        roundId,
        selectProduct,
        selectType,
        sort,
      }
    ) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        perPage,
        q,
        page,
        roundId,
        selectProduct,
        selectType,
        sort,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`connections`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getLogConnectionsRequest(
      { commit },
      {
        page,
        perPage,
        dateFrom,
        dateTo,
        q,
        selectCustomer,
        selectProduct,
        selectType,
        sort,
      }
    ) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        perPage,
        q,
        page,
        selectCustomer,
        selectProduct,
        selectType,
        sort,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`connections-request`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getLogLaunch({ commit }, payload) {
      const params = {
        ...payload,
        startDate: moment(payload.dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        endDate: moment(payload.dateTo).utc().format("YYYY-MM-DD HH:mm"),
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`list-launchlogs`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getLogError({ commit }, payload) {
      const params = {
        ...payload,
        startDate: moment(payload.dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        endDate: moment(payload.dateTo).utc().format("YYYY-MM-DD HH:mm"),
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`showErrors`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getTransactions(
      { commit },
      { page, limit, dateSelect, selectCustomer, selectProduct, selectCurrency }
    ) {
      const params = {
        createdAt: moment(dateSelect).utc().format("YYYY-MM-DD HH:mm"),
        customer: selectCustomer,
        currency: selectCurrency,
        product: selectProduct,
        limit,
        page,
      };

      return new Promise((resolve, reject) => {
        return axios
          .get(`transaction`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getTransactionsPlain(
      { commit },
      {
        page,
        perPage,
        dateFrom,
        dateTo,
        selectCustomer,
        selectProduct,
        selectCurrency,
        q,
        WL,
      }
    ) {
      const params = {
        // convertir utc
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        customerId: selectCustomer,
        currency: selectCurrency,
        product: selectProduct,
        perPage,
        q,
        page,
        WL,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`transaction/transactions`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getTransactionsProfit(
      { commit },
      {
        page,
        limit,
        dateFrom,
        dateTo,
        selectCustomer,
        selectProduct,
        selectCurrency,
        q,
        api,
      }
    ) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        customerId: selectCustomer,
        currency: selectCurrency,
        product: selectProduct,
        perPage: limit,
        q,
        page,
        urlApi: api?.value || "transaction/profit",
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`${params.urlApi}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getTransactionsProfitDetailedByUser(
      { commit },
      {
        page,
        limit,
        dateFrom,
        dateTo,
        selectCustomer,
        selectProduct,
        selectCurrency,
        q,
        playerName,
      }
    ) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        customerId: selectCustomer,
        currency: selectCurrency,
        product: selectProduct,
        perPage: limit,
        q,
        page,
        player: playerName !== "all" && playerName ? playerName : null,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`transaction/profit-detailed-by-user`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getTransactionsbyGame({ commit }, {
      page,
      limit,
      sorts,
      dateFrom,
      dateTo,
      brand,
      customer,
      products,
      currency,
      WL,
      q,
      api
    }) {
      const params = {
        dateFrom: moment(dateFrom).utc().format("YYYY-MM-DD HH:mm"),
        dateAt: moment(dateTo).utc().format("YYYY-MM-DD HH:mm"),
        customers: customer,
        currency: currency,
        products: products,
        brandId: brand,
        perPage: limit,
        page,
        sorts,
        q,
        urlApi: api?.value || "transaction/bygame",
        WL: WL,
      };
      return new Promise((resolve, reject) => {
        return axios
          .get(`${params.urlApi}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getCustomer(ctx) {
      return new Promise((resolve, reject) => {
        return axios
          .post("pages/customers")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getProducts(ctx) {
      return new Promise((resolve, reject) => {
        return axios
          .post("pages/products")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    mostPlayed({}, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .get("report/most-played", payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getCurrencys(ctx) {
      return new Promise((resolve, reject) => {
        return axios
          .get("transaction/currencys")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadExcel({}, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .get("report/most-played-excel", payload, {
            responseType: "blob",
          })
          .then((response) => {
            const filename = "most-played.xlsx";
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUsersPlayers({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        return axios
          .post("getUserByCustomerId", payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReportPlayers({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`${params.urlApi}`, null, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReportBetPerDay({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        return axios
          .get("report/bet-per-day", { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReportBetPerDay({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        return axios.get('report/bet-per-day', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportPlayersByBrands({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`${payload.urlApi}`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadReportFile({}, { url, filter, fileTitle }) {
      return new Promise((resolve, reject) => {
        return axios
          .get(url, {
            params: filter,
            responseType: "blob",
            headers: {
              // enviar la zona horaria del usuario
              "X-Timezone": momentTz.tz.guess(),
            },
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileTitle);
            document.body.appendChild(link);
            link.click();
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadReportFileReception(
      {},
      { url, filter, dataItemsWithoutFilter, fileTitle }
    ) {
      return new Promise((resolve, reject) => {
        return axios
          .post(
            url,
            { dataItemsWithoutFilter, filter },
            {
              responseType: "blob",
              headers: {
                // enviar la zona horaria del usuario
                "X-Timezone": momentTz.tz.guess(),
              },
            }
          )
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileTitle);
            document.body.appendChild(link);
            link.click();
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    ticketsSportsbook({}, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .post("product/sportsbook/tickets-transaction", payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    cancelTicket({}, payload) {
      return new Promise((resolve, reject) => {
        return axios
          .post(`product/sportsbook/cancelTicket`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getWlOptions(ctx, { customerId }) {
      return new Promise((resolve, reject) => {
        return axios
          .get(`transaction/get-wl?customerId=${customerId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
