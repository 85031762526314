<template>
<section>
  <div class="row match-height">
    <div class="col-md-8 col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ title }} {{ product.name }}</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 p-2">
                <b-form-checkbox  v-if="!environmentDefault" v-model="custom" class="custom-control-success" switch inline>
                  <b>Environment Customer</b>
                </b-form-checkbox>
                <b-form-checkbox v-if="!custom" v-model="environmentDefault" @change="defaultEnvironment" class="custom-control-success" switch inline>
                  <b>Environment Default</b>
                </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="scrollable">
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} modeLaunch</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <validation-observer>
                  <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Launch Type">
                          <v-select
                            v-model="selectedLaunchType"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Launch Required">
                          <v-select
                            v-model="selectedLaunchRequired"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsRequireds"/>
                        </b-form-group>
                        <b-form-group label="Launch Default">
                          <b-form-input v-model="defaultLaunchValue"/>
                        </b-form-group>
                      </validation-provider>
                  </b-form>
                </validation-observer>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} keyVar</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                  <b-form-group label="Add Key Var Name">
                      <b-form-input v-model="keyVarName"/>
                      <br>
                      <b-button variant="primary" class="mr-1" @click="addkeyVar()">
                          <feather-icon icon="PlusIcon" class="mr-50"/>
                          <span class="align-middle">Add Key Var</span>
                      </b-button>
                  </b-form-group>
                  <hr>
                  <div v-for="(item, index) in computedKeyVars" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                          Type: <strong>Object</strong> 
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemKeyVarConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} apigames</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Api Game Type">
                          <v-select
                            v-model="selectedTypeApiGames"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Api Game Required">
                          <v-select
                            v-model="selectedRequiredApiGames"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsRequireds"/>
                        </b-form-group>
                        <b-form-group label="Api Game Default">
                          <b-form-input v-model="defaultApiGamesValue"/>
                        </b-form-group>
                        <b-form-group label="Api Game Name">
                            <b-form-input v-model="apiGame"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addApigames()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Apigame</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in computedApiGames" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                          Type: <strong>Object</strong> 
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemapigamesConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Launch</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Launch Type">
                          <v-select
                            v-model="selectedTypeLaunch"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Launch Required">
                          <v-select
                            v-model="selectedRequiredLaunch"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsRequireds"/>
                        </b-form-group>
                        <b-form-group label="Launch Default">
                          <b-form-input v-model="defaultValueLaunch"/>
                        </b-form-group>
                        <b-form-group label="Launch Name">
                            <b-form-input v-model="launch"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemLaunch()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Launch</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentLaunch" :key="index">
                      <div>
                        Var: <strong>{{ item[0] }}</strong>
                      </div>
                      <div>
                        Required: <strong>{{ item[1].required }}</strong>
                      </div>
                      <div>
                        Default: <strong>{{ item[1].default }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemLaunchConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Auth</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Auth Type">
                          <v-select
                            v-model="selectedTypeAuth"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Auth Default">
                          <b-form-input v-model="defaultValueAuth"/>
                        </b-form-group>
                        <b-form-group label="Auth Name">
                            <b-form-input v-model="auth"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemAuth()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Auth</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentAuth" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemAuthConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Balance</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Balance Type">
                          <v-select
                            v-model="selectedTypeBalance"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Balance Default">
                          <b-form-input v-model="defaultValueBalance"/>
                        </b-form-group>
                        <b-form-group label="Balance Name">
                            <b-form-input v-model="balance"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemBalance()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Balance</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentBalance" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemBalanceConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Betwin</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Betwin Type">
                          <v-select
                            v-model="selectedTypeBetwin"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Betwin Default">
                          <b-form-input v-model="defaultValueBetwin"/>
                        </b-form-group>
                        <b-form-group label="Betwin Name">
                            <b-form-input v-model="betwin"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemBetwin()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Betwin</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentBetwin" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemBetwinConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Status</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Status Type">
                          <v-select
                            v-model="selectedTypeStatus"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Status Default">
                          <b-form-input v-model="defaultValueStatus"/>
                        </b-form-group>
                        <b-form-group label="Status Name">
                            <b-form-input v-model="status"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemStatus()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Status</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentStatus" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemStatusConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} Error</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="Error Type">
                          <v-select
                            v-model="selectedTypeError"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="Error Default">
                          <b-form-input v-model="defaultValueError"/>
                        </b-form-group>
                        <b-form-group label="Error Name">
                            <b-form-input v-model="error"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemError()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add Error</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmentError" :key="index">
                      <div>
                          Var: <strong>{{ Object.keys(item)[0] }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemErrorConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="custom">
          <div class="card-header">
            <h4 class="card-title">{{ title }} {{ product.name }} errorCodes</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div>
                    <b-form class="p-2">
                      <validation-provider>
                        <b-form-group label="errorCodes Type">
                          <v-select
                            v-model="selectedTypeerrorCodes"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsTypes"/>
                        </b-form-group>
                        <b-form-group label="errorCodes Required">
                          <v-select
                            v-model="selectedRequirederrorCodes"
                            label="name"
                            :reduce="(item) => item.type"
                            :options="optionsRequireds"/>
                        </b-form-group>
                
                        <b-form-group label="errorCodes Name">
                            <b-form-input v-model="errorCodes"/>
                        </b-form-group>
                      </validation-provider>

                      <b-button variant="primary" class="mr-1" @click="addItemerrorCodes()">
                        <feather-icon icon="PlusIcon" class="mr-50"/>
                        <span class="align-middle">Add errorCodes</span>
                    </b-button>
                  </b-form>
                  <hr>
                  <div v-for="(item, index) in environmenterrorCodes" :key="index">
                      <div>
                        Var: <strong>{{ item[0] }}</strong>
                      </div>
                      <div>
                        Required: <strong>{{ item[1].required }}</strong>
                      </div>
                      <div>
                        Default: <strong>{{ item[1].default }}</strong>
                      </div>
                      <b-button size="sm" :variant="'danger'" @click="deleteItemerrorCodesConfig(index)">
                          <feather-icon icon="Trash2Icon"/>
                      </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-2">
          <b-button variant="success" class="mr-2" @click="addJsonInput()">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Preview</span>
          </b-button>
          <b-button variant="primary" class="mr-2" @click="clearJsonInput()">
            <feather-icon :icon="'PlusIcon'" class="mr-50" />
            <span class="align-middle">Clear</span>
          </b-button>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Data Preview</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="mb-1">
                <json-viewer :value="environment" :expand-depth=5 copyable boxed/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>
<script>
import { BForm, BFormGroup, BFormInput, BButton, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import jsonViewer from 'vue-json-viewer'

  export default {
    components: {
      BForm, 
      BFormGroup, 
      BFormInput,
      BButton,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,
      vSelect,
      jsonViewer,
    },
    computed: {
      computedKeyVars() {
        return this.keyVars.map(item => item)
      },
      computedApiGames() {
        return this.apiGames.map(item => item)
      },
      environmentLaunch() {
        return Object.entries(this.environment.launch[0])
      },
      environmentAuth() {
        return this.authArr.map(item => item)
      },
      environmentBalance() {
        return this.balanceArr.map(item => item)
      },
      environmentBetwin() {
        return this.betwinArr.map(item => item)
      },
      environmentStatus() {
        return this.statusArr.map(item => item)
      },
      environmentError() {
        return this.errorArr.map(item => item)
      },
      environmenterrorCodes(){
        return Object.entries(this.environment.launch[0])
      }
    },
    data() {
      return {
        environmentDefault:false,
        custom: false,
        default: {
            modeLaunch: { type: Number, default: 0 },
            keyVar: {
                redirect: { type: Object },
                apigames: { type: Object },
                launch: { type: Object },
                auth: { type: Object },
                balance: { type: Object },
                betwin: { type: Object },
                status: { type: Object },
                error: { type: Object },
                errorCodes: { type: Object },
            },
            apigames: {
                host: { type: String, required: false },
                method: { type: String, default: 'GET' },
                headers: { type: Object, default: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                }},
                includeParams: { type: Boolean, required: false },
                params: { type: Object, default: {} },
                replaceImage: { type: Boolean, default: false },
                image: { type: String, default: '' },
                response: { type: Object },
            },
            launch: [
                {
                    brand: { type: String, required: false },
                    host: { type: String, required: false },
                    method: { type: String, default: 'GET' },
                    headers: { type: Object, default: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                    }},
                    includeParams: { type: Boolean, required: false },
                    params: { type: Object },
                    includeTokenProvider: { type: Boolean, default: false },
                    axios: { type: Object },
                },
            ],
            auth: {
                includeTokenProvider: { type: Boolean, default: false },
                convertInXML: { type: Boolean, default: false },
                useTimestamp: { type: Boolean, default: false },
                response: { type: Object },
            },
            balance: {
                includeTokenProvider: { type: Boolean, default: false },
                convertInXML: { type: Boolean, default: false },
                useTimestamp: { type: Boolean, default: false },
                response: { type: Object },
            },
            betwin: {
                includeTokenProvider: { type: Boolean, default: false },
                convertInXML: { type: Boolean, default: false },
                useTimestamp: { type: Boolean, default: false },
                response: { type: Object },
            },
            status: {
                includeTokenProvider: { type: Boolean, default: false },
                convertInXML: { type: Boolean, default: false },
                useTimestamp: { type: Boolean, default: false },
                getTransaction: { type: Boolean, default: true },
                response: { type: Object },
            },
            error: {
                convertInXML: { type: Boolean, default: false },
                useTimestamp: { type: Boolean, default: false },
                response: { type: Object },
            },
            errorCodes: [
                {
                    _code: { type: Number, required: false },
                    status: { type: Number, required: false },
                    message: { type: String, required: false },
                },
            ],
        },
        product: {},
        config: {},
        title: 'Config Environment',
        
        // modeLaunch
        selectedLaunchType: null,
        selectedLaunchRequired : null,
        defaultLaunchValue: null,
        
        // apigames
        selectedTypeApiGames: null,
        selectedRequiredApiGames: null,
        defaultApiGamesValue: null,
        apiGame: null,

        // launch 
        selectedTypeLaunch: null,
        selectedRequiredLaunch: null,
        defaultValueLaunch: null,
        launch: null,

        // auth data
        selectedTypeAuth: null,
        defaultValueAuth: null,
        auth: null,

        // balance data
        selectedTypeBalance: null,
        defaultValueBalance: null,
        balance: null,

        // betwin data
        selectedTypeBetwin: null,
        defaultValueBetwin: null,
        betwin: null,

        // status data
        selectedTypeStatus: null,
        defaultValueStatus: null,
        status: null,

        // error data
        selectedTypeError: null,
        defaultValueError: null,
        error: null,

        // error codes
        selectedTypeerrorCodes: null,
        selectedRequirederrorCodes: null,
        errorCodes: null,
        
        keyVarName: null,

        keyVars: [],
        apiGames: [],
        authArr: [],
        balanceArr: [],
        betwinArr: [],
        statusArr: [],
        errorArr: [],

        environment: {
            modeLaunch: null,
            keyVar: {},
            apigames: {},
            launch: [
              {},
            ],
            auth: {},
            balance: {},
            betwin: {},
            status: {},
            error: {},
            errorCodes: [
              {}
            ],
        },
        optionsTypes: [
            { type: Array, name: 'Array' },
            { type: String, name: 'String' },
            { type: Number, name: 'Number' },
            { type: Object, name: 'Object' },
            { type: Boolean, name: 'Boolean' },
            { type: Date, name: 'Date' },
        ],
        optionsRequireds: [
          { type: false, name: 'false' },
          { type: true, name: 'true' },
        ],
      }
    },
    mounted() {
      this.fetchProduct()
    },
    methods: {
      defaultEnvironment() {
        if(!this.custom) {
          this.environment = this.default
        }
      },
      addItemerrorCodes() {
        this.environment.errorCodes[0][this.errorCodes] = { 
          type: this.selectedTypeerrorCodes, 
          required: this.selectedRequirederrorCodes, 
          default:  this.defaultValueerrorCodes 
        } 

        this.environment.errorCodes = [...this.environment.errorCodes]
        this.errorCodes = null
        this.selectedTypeerrorCodes = null
        this.selectedRequirederrorCodes = null
        this.defaultValueerrorCodes = null
      },
      deleteItemerrorCodesConfig() {

      },
      addItemError(){
        let error = {}
        error[this.error] = { type: this.selectedTypeError, default:  this.defaultValueError }

        if(this.errorArr.find(item => item[this.error])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `error already exist`,
            }})
            return
        } else if(this.error == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `error is empty`,
            }})
            return
        }
        this.errorArr.push(error)
        this.error = null
        this.selectedTypeError = null 
        this.defaultValueError = null
      },
      deleteItemErrorConfig(){
        
      },
      addItemStatus(){
        let status = {}
        status[this.status] = { type: this.selectedTypeStatus, default:  this.defaultValueStatus }

        if(this.statusArr.find(item => item[this.status])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `status already exist`,
            }})
            return
        } else if(this.status == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `status is empty`,
            }})
            return
        }
        this.statusArr.push(status)
        this.status = null
        this.selectedTypeStatus = null 
        this.defaultValueStatus = null
      },
      deleteItemStatusConfig(){

      },
      addItemBetwin(){
        let betwin = {}
        betwin[this.betwin] = { type: this.selectedTypeBetwin, default:  this.defaultValueBetwin }

        if(this.betwinArr.find(item => item[this.betwin])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `betwin already exist`,
            }})
            return
        } else if(this.betwin == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `betwin is empty`,
            }})
            return
        }
        this.betwinArr.push(betwin)
        this.betwin = null
        this.selectedTypeBetwin = null 
        this.defaultValueBetwin = null
      },
      deleteItemBetwinConfig(index){

      },
      addItemBalance(){
        let balance = {}
        balance[this.balance] = { type: this.selectedTypeBalance, default:  this.defaultValueBalance }

        if(this.balanceArr.find(item => item[this.balance])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `balance already exist`,
            }})
            return
        } else if(this.balance == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `balance is empty`,
            }})
            return
        }
        this.balanceArr.push(balance)
        this.balance = null
        this.selectedTypeBalance = null 
        this.defaultValueBalance = null
      },
      deleteItemBalanceConfig(index) {

      },
      addItemAuth() {
        let auth = {}
        auth[this.auth] = { type: this.selectedTypeAuth, default:  this.defaultValueAuth }

        if(this.authArr.find(item => item[this.auth])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `auth already exist`,
            }})
            return
        } else if(this.auth == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `auth is empty`,
            }})
            return
        }
        this.authArr.push(auth)
        this.auth = null
        this.selectedTypeAuth = null 
        this.defaultValueAuth = null
      },
      deleteItemAuthConfig(index) {

      },
      addItemLaunch() {
        this.environment.launch[0][this.launch] = { 
          type: this.selectedTypeLaunch, 
          required: this.selectedRequiredLaunch, 
          default:  this.defaultValueLaunch 
        } 

        this.environment.launch = [...this.environment.launch]
        this.launch = null
        this.selectedTypeLaunch = null
        this.selectedRequiredLaunch = null
        this.defaultValueLaunch = null
      },
      deleteItemLaunchConfig(index) {
        
      },
      deleteItemapigamesConfig(index) {
        this.apiGames.splice(index, 1) 
      },
      addApigames() {
        let apiGame = {}
        apiGame[this.apiGame] = { type: this.selectedTypeApiGames, required: this.selectedRequiredApiGames, default:  this.defaultApiGamesValue }

        if(this.apiGames.find(item => item[this.apiGame])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `ApiGame already exist`,
            }})
            return
        } else if(this.apiGame == null) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `ApiGame is empty`,
            }})
            return
        }
        this.apiGames.push(apiGame)
        this.apiGame = null
        this.selectedTypeApiGames = null 
        this.selectedRequiredApiGames = null
        this.defaultApiGamesValue = null
      },
      deleteItemKeyVarConfig(index) {
        this.keyVars.splice(index, 1) 
      },
      addkeyVar() {
        let keyVar = {}
        keyVar[this.keyVarName] = { type: Object }

        if(this.keyVars.find(item => item[this.keyVarName])) {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Key var name already exist`,
            }})
            return
        } else if(this.keyVarName == '') {
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Key var name is empty`,
            }})
            return
        }
        this.keyVars.push(keyVar)
        this.keyVarName = ''
      },
      fetchProduct(){
        this.$store.dispatch('moduleProducts/fetchProduct', {id: this.$route.params.id, params: {config: 1}})
        .then((res) => {
          this.product = res.product
          this.config = res.config
        })
      },
      clearJsonInput() {
        this.environment = {
            modeLaunch: null,
            keyVar: {},
            apigames: {},
            launch: [
              {},
            ],
            auth: {},
            balance: {},
            betwin: {},
            status: {},
            error: {},
            errorCodes: [
              {}
            ],
        }
      },
      addJsonInput() {
        let keyVar, apiGame, auth, balance, betwin, status, error = {}

        for (let item of this.keyVars) {
          keyVar = { ...keyVar, ...item }
        }

        for (let item of this.apiGames) {
          apiGame = { ...apiGame, ...item }
        }

        for (let item of this.balanceArr) {
          balance = { ...balance, ...item }
        }

        for (let item of this.betwinArr) {
          betwin = { ...betwin, ...item }
        }

        for (let item of this.statusArr) {
          status = { ...status, ...item }
        }

        for (let item of this.errorArr) {
          error = { ...error, ...item }
        }

        this.environment.error = error

        this.environment.status = status

        this.environment.betwin = betwin

        this.environment.balance = balance

        this.environment.auth = auth

        this.environment.apigames = apiGame

        this.environment.keyVar = keyVar

        this.environment.modeLaunch = { type: this.selectedLaunchType, default: this.defaultLaunchValue, required: this.selectedLaunchRequired }

        console.log(this.environment)
      }
    },
  }
</script>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";

    #add-new-user-sidebar {
        .vs__dropdown-menu {
            max-height: 200px !important;
        }
    }

    .scrollable{
        max-height: 400px;
        overflow-y: auto;
    }
</style>